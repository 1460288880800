import React, {useCallback, useMemo, useState} from 'react';
import {useCollapse} from 'react-collapsed';
import {useSelector} from 'react-redux';

import {getBetSlipCount} from '../../../Redux/AppSlice/AppSelectors';

import EventsList from './EventsList';

import {useStorage} from '../../../Hooks';

import {formatEventName} from '../../../Utils/EventNamesFormatter';

import {ReactComponent as StarIcon} from '../../../Assets/Icons/Globals/star.svg';
import {ReactComponent as CashoutIcon} from '../../../Assets/Icons/Globals/cashout.svg';
import {ReactComponent as TopTriangle} from '../../../Assets/Icons/Globals/topArrow.svg';
import {ReactComponent as DownTriangle} from '../../../Assets/Icons/Globals/downArrow.svg';
import {ReactComponent as CircleTopIcon} from '../../../Assets/Icons/Globals/circleTopIcon.svg';
import {ReactComponent as StarActiveIcon} from '../../../Assets/Icons/Globals/starWithBackground.svg';

const MarketListItem = ({
  index,
  team1Name,
  team2Name,
  marketItem,
  favouriteMarkets,
  setFavouriteMarkets,
}) => {
  const betSlipCount = useSelector(getBetSlipCount);

  const [isExpanded, setExpanded] = useState(true);

  const {setFavouriteMarketsToStorage} = useStorage();

  const {getCollapseProps, getToggleProps} = useCollapse({
    isExpanded,
  });

  const TriangleIcon = useMemo(
    () => (isExpanded ? CircleTopIcon : CircleTopIcon),
    [isExpanded],
  );

  const isFavourite = useMemo(
    () => !!favouriteMarkets?.includes(marketItem?.market_type),
    [favouriteMarkets, marketItem?.market_type],
  );

  const changeFavouriteItem = useCallback(
    e => {
      setFavouriteMarkets(prevState => {
        e.stopPropagation();
        const newData = [...prevState];
        const itemIndex = newData.indexOf(marketItem?.market_type);
        if (itemIndex > -1) {
          newData.splice(itemIndex, 1);
        } else {
          newData?.push(marketItem?.market_type);
        }
        return newData;
      });
      setFavouriteMarketsToStorage(marketItem?.market_type)?.then?.();
    },
    [
      setFavouriteMarkets,
      marketItem?.market_type,
      setFavouriteMarketsToStorage,
    ],
  );

  return (
    <div className="full-width marketContainerRadius whiteBackground">
      <div
        className="flex justify-between items-center marketsWrapper"
        {...getToggleProps({
          onClick: () => setExpanded(prevState => !prevState),
        })}>
        <div className="pa-sm row items-center justify-between full-width">
          <div className="flex gap-5 items-center">
            <div onClick={changeFavouriteItem} className="flex rowCenter">
              <StarActiveIcon
                width={12}
                height={12}
                fill={!!isFavourite ? 'var(--appYellow)' : 'var(--white)'}
              />
            </div>
            <span className="whiteText font-12 bold-600">
              {formatEventName({
                team1Name,
                team2Name,
                name: marketItem?.name,
              })}
            </span>
          </div>
          <div className="row items-center gap-5">
            {marketItem?.cashout && <CashoutIcon width={12} height={12} />}
            <TriangleIcon width={12} height={12} fill="white" />
          </div>
        </div>
      </div>
      <div
        {...getCollapseProps()}
        className={`${
          !!isExpanded ? 'whiteBackground' : 'blueBackground hidden'
        }`}>
        <div style={{padding: '4px 2px 2px 2px', borderRadius: 5}}>
          {marketItem?.data?.map((eventItem, index) => (
            <div className="flex row wrap justify-between" key={index}>
              <EventsList
                key={betSlipCount}
                team1Name={team1Name}
                team2Name={team2Name}
                marketItem={eventItem}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
// DO NOT PUT IN MEMO
export default MarketListItem;
