export const CASH = 'Cash';
export const M_PESA = 'FastHub';
export const BANK = 'FasthubCard';
export const EZY_PESA = 'EzyPesa';
export const AIRTEL_NEW = 'AirtelVoda';
export const TIGO_PESA = 'FastHubTigo';
export const HALO_PESA = 'FastHubHalotel';

export let PAYMENT_SERVICES = [
  {
    id: 13071,
    title: M_PESA,
    logo: require('../Assets/Images/PaymentServices/MPesa.png'),
  },
  {
    id: 13081,
    title: HALO_PESA,
    logo: require('../Assets/Images/PaymentServices/HaloPesa.png'),
  },
  {
    id: 13080,
    title: TIGO_PESA,
    logo: require('../Assets/Images/PaymentServices/TigoPesa.png'),
  },
  {
    id: 13825,
    title: AIRTEL_NEW,
    logo: require('../Assets/Images/PaymentServices/AirtelMoney.png'),
  },
  {
    id: 14921,
    title: BANK,
    logo: require('../Assets/Images/PaymentServices/bank.png'),
  },
];

export let SOCIALS = [
  {
    id: 1,
    logo: 'facebook',
    href: 'https://www.facebook.com/www.pigabet.co.tz',
  },
  {
    id: 2,
    logo: 'x',
    href: 'https://x.com/PigabetTZ',
  },
  {
    id: 4,
    logo: 'instagram',
    href: 'https://www.instagram.com/pigabet/',
  },

  {
    id: 3,
    logo: 'whatsapp',
    href: 'https://api.whatsapp.com/send/?phone=+255620818181&text&app_absent=0',
  },
  {
    id: 5,
    logo: 'youtube',
    href: 'https://www.youtube.com/channel/UCcJahVgDWRXGzhvbsyMvOjA',
  },
];

export const PIGABET_SHOP_ID = 115265;
export const LE_GRANDE_CASINO_ID = 104816;
export const PALM_BEACH_CASINO_ID = 104817;
export const LAS_VEGAS_CASINO_ID = 1048189;
export const CASH_WITHDRAW_SERVICE_ID = 353;
export const CASINO_MIN_WITHDRAW_AMOUNT = 20000;
export const OPERATOR_MIN_WITHDRAW_AMOUNT = 1000;
export const PIGABET_SHOP_TITLE = 'PIGABET_SHOP';
export const LE_GRANDE_CASINO_TITLE = 'LE_GRANDE';
export const LAS_VEGAS_CASINO_TITLE = 'LAS_VEGAS';
export const PALM_BEACH_CASINO_TITLE = 'PALM_BEACH';
export const OPERATOR_MAX_WITHDRAW_AMOUNT = 5000000;
export const CASINO_MAX_WITHDRAW_AMOUNT = 100000000;
export const PIGABET_SHOP_MAX_WITHDRAW_AMOUNT = 5000000;

export let CASINO_METHODS = [
  {
    background: '#9BD5E0',
    id: CASH_WITHDRAW_SERVICE_ID,
    title: LE_GRANDE_CASINO_TITLE,
    office_id: LE_GRANDE_CASINO_ID,
    service: CASH_WITHDRAW_SERVICE_ID,
    logo: require('../Assets/Images/PaymentServices/LeGrandeCasino.png'),
  },
  {
    background: '#FBC589',
    id: CASH_WITHDRAW_SERVICE_ID,
    title: PALM_BEACH_CASINO_TITLE,
    office_id: PALM_BEACH_CASINO_ID,
    service: CASH_WITHDRAW_SERVICE_ID,
    logo: require('../Assets/Images/PaymentServices/PalmBeachCasino.png'),
  },
  {
    background: 'var(--appRed)',
    id: CASH_WITHDRAW_SERVICE_ID,
    title: LAS_VEGAS_CASINO_TITLE,
    office_id: LAS_VEGAS_CASINO_ID,
    service: CASH_WITHDRAW_SERVICE_ID,
    logo: require('../Assets/Images/PaymentServices/LasVegasCasino.png'),
  },
];

export const PIGABET_SHOP = {
  title: PIGABET_SHOP_TITLE,
  office_id: PIGABET_SHOP_ID,
  background: 'var(--appBlue)',
  id: CASH_WITHDRAW_SERVICE_ID,
  service: CASH_WITHDRAW_SERVICE_ID,
  logo: require('../Assets/Images/PaymentServices/HarbourViewTowers.png'),
};
