import React, {memo, useMemo, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams, useSearchParams} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {getIsLoggedIn} from '../../../Redux/AppSlice/AppSelectors';

import {useScreenSize} from '../../../Hooks';
import useCasino from '../../../Hooks/UseCasino';

import {BREAKPOINT_XS} from '../../../Constants/Globals';

import GameItem from './GameItem';
import {AppButton} from '../../UI';
import CasinoGamesSkeletons from '../../UI/Skeletons/CasinoGamesSkeletons';

const GamesListContainer = ({isGamesLoading, data, moreItemsLoading}) => {
  const params = useParams();
  const {t} = useTranslation();
  const {width} = useScreenSize();
  const [getParam] = useSearchParams();
  const {goToCasinoGameScreen} = useCasino();

  const isLoggedIn = useSelector(getIsLoggedIn);

  const containerRef = useRef(null);

  const sportGameIdParam = useMemo(
    () => getParam.get('sportGameId'),
    [getParam],
  );

  const isGameVisible = useMemo(
    () => !!sportGameIdParam && !!isLoggedIn,
    [sportGameIdParam, isLoggedIn],
  );
  return (
    <div
      ref={containerRef}
      style={{marginTop: 0}}
      className={`row wrap col-gutter-sm casinoGameListContainer pb-md ${
        width < BREAKPOINT_XS ? 'pt-sm' : 'pt-sm'
      }`}>
      {isGamesLoading ? (
        <CasinoGamesSkeletons containerRef={containerRef} />
      ) : (
        <>
          {isGameVisible && (
            <AppButton
              type={6}
              className="ma-sm textLeft"
              title={t(
                params?.category === 'slots'
                  ? 'lastSlotsPlayed'
                  : params?.category === 'skill'
                  ? 'lastSkillPlayed'
                  : params?.category === 'BoostedRTP'
                  ? 'lastBoostedRTPPlayed'
                  : 'lastLiveCasinoPlayed',
              )}
            />
          )}
          {data?.length > 0 ? (
            data?.map((gameItem, index) => (
              <GameItem
                index={index}
                gameItem={gameItem}
                key={gameItem?.extearnal_game_id}
                clickHandler={({mode}) => {
                  goToCasinoGameScreen({game: gameItem, mode: mode});
                }}
              />
            ))
          ) : (
            <span className="textCenter font-24 py-xl full-width whiteText">
              {t('noGames')}
            </span>
          )}
          {moreItemsLoading && (
            <CasinoGamesSkeletons containerRef={containerRef} />
          )}
        </>
      )}
    </div>
  );
};

export default memo(GamesListContainer);
