import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {
  useParams,
  useNavigate,
  useLocation,
  useSearchParams,
  createSearchParams,
} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import useCasino from '../../../Hooks/UseCasino';
import {useScreenSize, useStorage} from '../../../Hooks';

import {setAuthModal} from '../../../Redux/AppSlice';
import {getUser} from '../../../Redux/UserSlice/UserSelectors';
import {getIsLoggedIn} from '../../../Redux/AppSlice/AppSelectors';

import {AppButton} from '../../../Components/UI';
import HomeProviders from '../../../Components/Home/HomeProviders';
import MobileHeader from '../../../Components/Header/MobileHeader';
import GamesListContainer from '../../../Components/Casino/Games/GamesListContainer';
import LobbyGamesContainer from '../../../Components/Casino/Lobby/LobbyGamesContainer';
import GameActionBarHeader from '../../../Components/Casino/GameActionBar/GameActionBarHeader';
import GameActionBarFooter from '../../../Components/Casino/GameActionBar/GameActionBarFooter';

import {
  CATEGORY_LIVE_CASINO_ID,
  CATEGORY_SKILL_GAMES_ID,
  CATEGORY_SLOTS_GAMES_ID,
  PROVIDERS_ALL_GAMES_NAME,
  SUB_CATEGORIES_LAST_PLAYED,
  CATEGORY_BOOSTED_RTP_GAMES_ID,
} from '../../../Constants/CasinoMenuFilter';
import {FUN, REAL, SITE_ID} from '../../../Constants/Socket';
import {
  BREAKPOINT_XS,
  APP_MODAL_CLOSE_TIMEOUT_MS,
} from '../../../Constants/Globals';

import {ReactComponent as CasinoGameBack} from '../../../Assets/Icons/Casino/CasinoGameBack.svg';
import {ReactComponent as FullScreenArrowDown} from '../../../Assets/Icons/Casino/FullScreenArrowDown.svg';
import {ReactComponent as FullScreenArrowTop} from '../../../Assets/Icons/Casino/FullScreenArrowTop.svg';

import './index.scss';

const Casino = () => {
  const params = useParams();
  const {t} = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {width} = useScreenSize();
  const [getParam, setGetParam] = useSearchParams();

  const {getCasinoGames} = useCasino();
  const {getLastPlayedGamesFromStorage} = useStorage();

  const user = useSelector(getUser);
  const isLoggedIn = useSelector(getIsLoggedIn);

  const offset = useRef(0);
  const observerRef = useRef(null);
  const inputValueRef = useRef(getParam.get('search'));

  const [games, setGames] = useState([]);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [totalCounts, setTotalCounts] = useState(0);
  const [lastPlayedGames, setLastPlayedGames] = useState([]);
  const [isGamesLoading, setIsGamesLoading] = useState(false);
  const [moreItemsLoading, setMoreItemsLoading] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(width < BREAKPOINT_XS);

  const modeParam = useMemo(() => getParam.get('mode'), [getParam]);
  const searchValue = useMemo(() => getParam.get('search'), [getParam]);
  const providerParam = useMemo(() => getParam.get('provider'), [getParam]);
  const gameIdParam = useMemo(() => getParam.get('casinoGameId'), [getParam]);

  const subCategoryIdParam = useMemo(
    () => getParam.get('subCategoryId'),
    [getParam],
  );

  const isLobbyPage = useMemo(
    () => location?.pathname?.includes('lobby'),
    [location?.pathname],
  );

  const src = useMemo(
    () =>
      (
        'https://games.pigabet.co.tz/LaunchGame' +
        `?partnerId=${SITE_ID}` +
        '&language=en' +
        '&devicetypeid=1' +
        `&gameId=${gameIdParam}` +
        `&openType=${modeParam}` +
        `&token=${user?.auth_token}`
      )?.trim(),
    [gameIdParam, modeParam, user?.auth_token],
  );

  const LAST_PLAYED_DATA = useMemo(() => {
    // const storageData = getLastPlayedGamesFromStorage()
    //   ?.filter(item =>
    //     params?.category === 'slots'
    //       ? item?.categoryId === CATEGORY_SLOTS_GAMES_ID
    //       : params?.category === 'liveCasino'
    //       ? item?.categoryId === CATEGORY_LIVE_CASINO_ID
    //       : params?.category === 'skill'
    //       ? item?.categoryId === CATEGORY_SKILL_GAMES_ID
    //       : params?.category === 'boostedRTP'
    //       ? item?.categoryId === CATEGORY_BOOSTED_RTP_GAMES_ID
    //       : item,
    //   )
    //   ?.map(item => `${item?.gameId}`);
    //
    // return lastPlayedGames?.filter(item =>
    //   storageData?.includes(item?.extearnal_game_id),
    // );
    return lastPlayedGames;
  }, [lastPlayedGames]);

  const onShowMore = useCallback(() => {
    if (moreItemsLoading || !games?.length) {
      return;
    }
    setMoreItemsLoading(true);
    offset.current = offset.current + 30;
    getCasinoGames({
      extra_category_id:
        params?.category === 'liveCasino' ? subCategoryIdParam : '',
      provider: providerParam === PROVIDERS_ALL_GAMES_NAME ? '' : providerParam,
      category:
        params?.category === 'liveCasino'
          ? CATEGORY_LIVE_CASINO_ID
          : subCategoryIdParam,
      offset: offset.current,
      limit: offset.current + 30,
    })
      ?.then(res => {
        setGames(prevState => [...prevState, ...res?.data?.games]);
      })
      ?.finally(() => setMoreItemsLoading(false));
  }, [
    games?.length,
    providerParam,
    getCasinoGames,
    moreItemsLoading,
    params?.category,
    subCategoryIdParam,
  ]);

  const handleObserver = useCallback(
    entries => {
      const target = entries[0];
      const currentScrollY = window.scrollY;

      if (target.isIntersecting && currentScrollY > lastScrollY) {
        onShowMore();
      }
      setLastScrollY(currentScrollY);
    },
    [lastScrollY, onShowMore],
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {threshold: 0.5});
    if (observerRef.current) {
      observer.observe(observerRef.current);
    }
    return () => {
      if (observerRef.current) observer.unobserve(observerRef.current);
    };
  }, [observerRef.current, lastScrollY]);

  useEffect(() => {
    if (subCategoryIdParam === SUB_CATEGORIES_LAST_PLAYED) {
      setIsGamesLoading(true);
      const externalIdsString = getLastPlayedGamesFromStorage()
        ?.filter(item =>
          params?.category === 'slots'
            ? item?.categoryId === CATEGORY_SLOTS_GAMES_ID
            : params?.category === 'liveCasino'
            ? item?.categoryId === CATEGORY_LIVE_CASINO_ID
            : params?.category === 'skill'
            ? CATEGORY_SKILL_GAMES_ID
            : params?.category === 'boostedRTP'
            ? item?.categoryId === CATEGORY_BOOSTED_RTP_GAMES_ID
            : true,
        )
        ?.map(game => game?.gameId)
        ?.join(',');
      if (externalIdsString) {
        getCasinoGames({
          limit: 30,
          externalIds: externalIdsString,
        })
          ?.then?.(response => {
            const specificGames = response?.data?.games;
            specificGames.sort(
              (a, b) =>
                externalIdsString.indexOf(a?.extearnal_game_id) -
                externalIdsString.indexOf(b?.extearnal_game_id),
            );
            setLastPlayedGames(specificGames);
          })
          ?.finally(() => {
            setTimeout(() => {
              setIsGamesLoading(false);
            }, 300);
          });
      } else {
        setTimeout(() => {
          setIsGamesLoading(false);
        }, 300);
      }
    } else {
      offset.current = 0;
      setIsGamesLoading(true);
      getCasinoGames({
        extra_category_id:
          params?.category === 'liveCasino' ? subCategoryIdParam : '',
        provider:
          providerParam === PROVIDERS_ALL_GAMES_NAME ? '' : providerParam,
        category:
          params?.category === 'liveCasino'
            ? CATEGORY_LIVE_CASINO_ID
            : subCategoryIdParam,
        offset: 0,
        limit: 30,
      })
        ?.then(res => {
          setTotalCounts(+res?.data?.total_count);
          setGames(res?.data?.games);
        })
        ?.finally(() => {
          setTimeout(() => {
            setIsGamesLoading(false);
          }, 300);
        });
    }
  }, [providerParam, subCategoryIdParam, params?.category]);

  return (
    <div className="casinoPageContainer">
      {gameIdParam &&
        (modeParam === FUN || (modeParam === REAL && isLoggedIn)) && (
          <div
            style={{
              width,
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              height: '100%',
              position: 'fixed',
              zIndex: width > BREAKPOINT_XS ? 100 : 22,
            }}>
            <div
              style={{
                top: 0,
                height: 20,
                position: 'absolute',
              }}
              className="full-width rowCenter">
              {!isMenuOpen && (
                <div
                  onClick={() => setIsMenuOpen(true)}
                  className="cursor-pointer">
                  <FullScreenArrowDown
                    width={80}
                    height={80}
                    fill={'rgba(2, 39, 81, 0.3)'}
                  />
                </div>
              )}
              {isMenuOpen && (
                <div className="full-width flex column items-center">
                  <MobileHeader
                    showFilters={false}
                    leftIcon={
                      <div
                        className="ml-md cursor-pointer"
                        onClick={() => {
                          setIsMenuOpen(false);
                          getParam.delete('mode');
                          getParam.delete('casinoGameId');
                          setGetParam(getParam);
                        }}>
                        <CasinoGameBack />
                      </div>
                    }
                  />
                  <div
                    style={{
                      top: 10,
                      height: 20,
                      position: 'absolute',
                    }}
                    className="cursor-pointer"
                    onClick={() => setIsMenuOpen(false)}>
                    <FullScreenArrowTop
                      width={80}
                      height={80}
                      fill={'rgba(2, 39, 81, 0.3)'}
                    />
                  </div>
                </div>
              )}
            </div>
            <iframe
              src={src}
              width="100%"
              height="100%"
              scrolling="no"
              title="casino_game"
            />
          </div>
        )}
      {/*{isGameVisible && (*/}
      {/*  <>*/}
      {/*    <GameActionBarFooter*/}
      {/*      setIsFullScreen={setIsFullScreen}*/}
      {/*      gameTitle={*/}
      {/*        games?.find(item => +item?.extearnal_game_id === +gameId)?.name ??*/}
      {/*        LAST_PLAYED_DATA?.find(*/}
      {/*          item => +item?.extearnal_game_id === +gameId,*/}
      {/*        )?.name ??*/}
      {/*        ''*/}
      {/*      }*/}
      {/*    />*/}
      {/*    <div*/}
      {/*      className={`${*/}
      {/*        isFullScreen*/}
      {/*          ? 'fullScreenContainer overflow-hidden darkBlueBackground'*/}
      {/*          : `mt-md gameIframeContainer`*/}
      {/*      }`}>*/}
      {/*      {isFullScreen && (*/}
      {/*        <GameActionBarHeader setIsFullScreen={setIsFullScreen} />*/}
      {/*      )}*/}
      {/*      <div*/}
      {/*        className={`full-width ${*/}
      {/*          isFullScreen ? 'casinoGameIframe' : 'full-height'*/}
      {/*        }`}>*/}
      {/*        <iframe*/}
      {/*          src={src}*/}
      {/*          scrolling="no"*/}
      {/*          className="fit"*/}
      {/*          title="casino_game"*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </>*/}
      {/*)}*/}
      {/*{!isLobbyPage && <HomeProviders />}*/}
      {/*{!isGameVisible && !isLobbyPage && <HomeProviders />}*/}
      {/*{!isGameVisible && width > BREAKPOINT_XS && (*/}
      {/*  <CasinoActionBar inputValueRef={inputValueRef} showLastPlayedButton />*/}
      {/*)}*/}
      {isLobbyPage ? (
        <LobbyGamesContainer />
      ) : (
        <GamesListContainer
          isGamesLoading={isGamesLoading}
          moreItemsLoading={moreItemsLoading}
          data={
            subCategoryIdParam === SUB_CATEGORIES_LAST_PLAYED
              ? LAST_PLAYED_DATA
              : games
          }
        />
      )}

      {games?.length < totalCounts &&
        !isLobbyPage &&
        subCategoryIdParam !== SUB_CATEGORIES_LAST_PLAYED && (
          // <div className="rowCenter my-xl">
          //   <AppButton
          //     type={3}
          //     width={150}
          //     onClick={onShowMore}
          //     title={<span className="boldText">{t('showMore')}</span>}
          //   />
          // </div>
          <div ref={observerRef} style={{height: '1px', width: '100%'}} />
        )}
    </div>
  );
};

export default Casino;
