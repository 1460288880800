import React, {
  memo,
  useMemo,
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
} from 'react';
import {useCollapse} from 'react-collapsed';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {
  useParams,
  useNavigate,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import {Virtuoso} from 'react-virtuoso';

import './index.scss';

import GameItem from './GameItem';
import {AppButton} from '../../UI';
import Regions from '../Sports/Regions';
import SelectedGame from '../SelectedGame';
import AccordionItem from './AccordionItem';
import RegionsModal from '../ActionBar/RegionsModal';
import GameSkeletons from '../../UI/Skeletons/GameSkeletons';
import CompetitionsWrapper from '../Sports/Competitions/CompetitionsWrapper';

import {useScreenSize, useSocket} from '../../../Hooks';

import {
  getSportsArray,
  getIsGameDataLoading,
  getFavouriteCompetitionsObject,
  getIsPopularCompetitionsLoading,
} from '../../../Redux/SportSlice/SportSelectors';
import {
  getWssSocket,
  getShowBetSlip,
  getIsLoggedIn,
} from '../../../Redux/AppSlice/AppSelectors';

import {
  setSubIds,
  setShowSideBar,
  setIsGameDataLoading,
  setFavouriteCompetitionsObject,
} from '../../../Redux/SportSlice';

import {
  MATCH_FILTERS,
  FOOTBALL_ALIAS,
  MARKETS_WINNER,
  MARKETS_TOTALS,
  MARKETS_HANDICAP,
  MARKETS_ODD_EVEN,
  LIMIT_OF_ALL_GAMES,
  FILTERS_TODAY_NAME,
  FILTERS_LIVE_CALENDAR,
  MARKETS_DOUBLE_CHANCE,
  FILTERS_BOOSTED_ODDS_NAME,
  MARKETS_BOTH_TEAM_TO_SCORE,
  FILTERS_POPULAR_MATCHES_NAME,
  FILTERS_UPCOMING_MATCHES_NAME,
  FILTERS_FAVOURITE_COMPETITIONS_NAME,
} from '../../../Constants/MatchFilters';
import {
  GET_GAMES,
  GET_BOOSTED_GAMES,
  GET_INITIAL_GAMES,
  GET_USER_DATA_PERSONALIZATION,
} from '../../../Constants/Socket';
import {
  BREAKPOINT_LG,
  BREAKPOINT_MD,
  BREAKPOINT_XS,
  BREAKPOINT_XXS,
} from '../../../Constants/Globals';
import {Flags} from '../../../Constants/Flags';

import {ReactComponent as TimeIcon} from '../../../Assets/Icons/Globals/time.svg';
import {ReactComponent as MenuIcon} from '../../../Assets/Icons/Globals/menu.svg';
import {ReactComponent as TopTriangle} from '../../../Assets/Icons/Globals/topArrow.svg';
import {ReactComponent as DownTriangle} from '../../../Assets/Icons/Globals/downArrow.svg';
import {DROPDOWN_MARKETS_NAMES} from '../../../Constants/FixedMarketFiltersArray';
import FixedMarketsFilters from '../FixedMarketsFilters';
import {da} from 'date-fns/locale';

const Games = ({isGrouped, setIsGrouped}) => {
  const params = useParams();
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {width} = useScreenSize();
  const [getParam, setGetParam] = useSearchParams();
  const {setFavouriteCompetition} = useSocket();

  const sports = useSelector(getSportsArray);
  const wssSocket = useSelector(getWssSocket);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const showBetSlip = useSelector(getShowBetSlip);
  const isGameDataLoading = useSelector(getIsGameDataLoading);
  const isPopularCompetitionsLoading = useSelector(
    getIsPopularCompetitionsLoading,
  );

  const favouriteCompetitionsObject = useSelector(
    getFavouriteCompetitionsObject,
  );

  const [games, setGames] = useState([]);
  const [isExpanded, setExpanded] = useState(false);
  const [height, setHeight] = useState(
    document?.querySelector?.('.sportsWrapper')?.offsetHeight,
  );

  const [selectedRegion, setSelectedRegion] = useState({});

  const {getCollapseProps, getToggleProps} = useCollapse({isExpanded});

  const getMarketParam = getParam.get('market');
  const sportParam = useMemo(() => getParam.get('sport'), [getParam]);
  const searchValue = useMemo(() => getParam.get('search'), [getParam]);
  const sportGameIdParam = useMemo(
    () => getParam.get('sportGameId'),
    [getParam],
  );

  const gameFilterParam = useMemo(() => getParam.get('gameFilter'), [getParam]);

  const activeSportCount = useMemo(
    () => sports?.find(item => item?.alias === sportParam)?.game,
    [sportParam, sports],
  );

  const isFootball = useMemo(
    () => getParam.get('sport') === FOOTBALL_ALIAS,
    [getParam],
  );

  const GAMES_DATA = useMemo(
    () =>
      games
        // ?.filter(
        //   item =>
        //     item?.team1_name
        //       ?.toLocaleLowerCase()
        //       ?.includes(
        //         ((!!params?.region ? !!searchValue || '' : '') || '')
        //           ?.toLocaleLowerCase()
        //           ?.trim(),
        //       ) ||
        //     item?.team2_name
        //       ?.toLocaleLowerCase()
        //       ?.includes(
        //         ((!!params?.region ? !!searchValue || '' : '') || '')
        //           ?.toLocaleLowerCase()
        //           ?.trim(),
        //       ),
        // )
        ?.sort((a, b) =>
          gameFilterParam === FILTERS_TODAY_NAME
            ? a?.competition_order - b?.competition_order
            : a?.start_ts - b?.start_ts,
        ),
    [games, gameFilterParam],
  );

  const isResetDisabled = useMemo(() => {
    return (
      !!searchValue ||
      !!params?.competition ||
      (getMarketParam !== 'matchResult' && width < BREAKPOINT_XS)
    );
  }, [getMarketParam, params?.competition, searchValue, width]);

  const showLoggedInToSee = useMemo(
    () =>
      !isGameDataLoading &&
      !isLoggedIn &&
      gameFilterParam === FILTERS_FAVOURITE_COMPETITIONS_NAME,
    [gameFilterParam, isGameDataLoading, isLoggedIn],
  );

  const groupedData = useMemo(
    () =>
      Object.values(
        GAMES_DATA?.reduce((acc, item) => {
          const key = item?.competition_id;

          if (!acc?.[key]) {
            acc[key] = [];
          }

          acc?.[key]?.push?.(item);

          return acc;
        }, {}) || {},
      )?.sort((a, b) => a?.[0]?.competition_order - b?.[0]?.competition_order),
    [GAMES_DATA],
  );

  const showShowMoreButton = useMemo(
    () =>
      width < BREAKPOINT_XS &&
      gameFilterParam !== FILTERS_POPULAR_MATCHES_NAME &&
      gameFilterParam !== FILTERS_TODAY_NAME &&
      gameFilterParam !== FILTERS_LIVE_CALENDAR &&
      GAMES_DATA?.length >= LIMIT_OF_ALL_GAMES &&
      +activeSportCount >= LIMIT_OF_ALL_GAMES &&
      !params?.region &&
      !isGameDataLoading,
    [
      width,
      gameFilterParam,
      params?.region,
      activeSportCount,
      isGameDataLoading,
      GAMES_DATA?.length,
    ],
  );

  const onShowMore = useCallback(() => {
    if (width < BREAKPOINT_XS) {
      dispatch(setShowSideBar(true));
    } else {
      setExpanded(prevState => {
        if (!!prevState) {
          setSelectedRegion({});
        }
        return !prevState;
      });
    }
  }, [dispatch, width]);

  const onFavouriteSelect = useCallback(
    competitionId => {
      const newFavouriteCompetitionsObject = {...favouriteCompetitionsObject};
      if (
        Object.keys(newFavouriteCompetitionsObject || {})?.includes(
          `${competitionId}`,
        )
      ) {
        delete newFavouriteCompetitionsObject[`${competitionId}`];
      } else {
        newFavouriteCompetitionsObject[`${competitionId}`] = {};
      }

      dispatch(setFavouriteCompetitionsObject(newFavouriteCompetitionsObject));
      setFavouriteCompetition({idsObject: newFavouriteCompetitionsObject});
    },
    [favouriteCompetitionsObject, dispatch, setFavouriteCompetition],
  );

  const onCheckInUpcomingMatches = useCallback(() => {
    if (gameFilterParam === FILTERS_UPCOMING_MATCHES_NAME) {
      setGames([]);
      return;
    }
    //TODO CHANGE THIS PART WITH NEW GETPARAM LOGIC
    if (params?.region && params?.competition) {
      navigate(
        `/sports/${FILTERS_UPCOMING_MATCHES_NAME}/${sportParam}/${params?.region}/${params?.competition}`,
      );
    } else {
      navigate(`/sports/${FILTERS_UPCOMING_MATCHES_NAME}/${sportParam}`);
    }
  }, [
    navigate,
    sportParam,
    gameFilterParam,
    params?.region,
    params?.competition,
  ]);

  const onReset = useCallback(() => {
    if (
      !searchValue &&
      !params?.region &&
      !params?.competition &&
      getMarketParam
    ) {
      getParam.delete('market');
      getParam.delete('marketFilter');
      setGetParam(getParam);
      return;
    }
    navigate(`/sports/${MATCH_FILTERS?.[gameFilterParam]?.name}/${sportParam}`);
    // if (!params?.region && !params?.competition && !!searchValue) {
    //   getInitialGames({
    //     activeSportAlias: sportParam,
    //     gameFilters: MATCH_FILTERS?.[gameFilterParam]?.status,
    //   });
    // }
  }, [
    navigate,
    getParam,
    setGetParam,
    searchValue,
    sportParam,
    gameFilterParam,
    getMarketParam,
    params?.region,
    params?.competition,
  ]);

  const onMessageCb = useCallback(
    async event => {
      const data = JSON.parse(event.data);
      if (
        data?.rid?.includes(GET_INITIAL_GAMES) ||
        data?.rid === GET_BOOSTED_GAMES
      ) {
        dispatch(setSubIds({games: data?.data?.subid}));
        const sportObject = Object.values(data?.data?.data?.sport || {})?.find(
          item => item?.alias === sportParam,
        );
        const regionObject = sportObject?.region;

        const gamesArray = [];
        for (const region in regionObject) {
          for (const competition in regionObject[region]?.competition) {
            for (const currentGame in regionObject[region]?.competition?.[
              competition
            ]?.game) {
              gamesArray.push({
                ...regionObject[region]?.competition?.[competition]?.game?.[
                  currentGame
                ],
                competition_name:
                  regionObject[region]?.competition?.[competition]?.name,
                competition_id:
                  regionObject[region]?.competition?.[competition]?.id,
                competition_order:
                  regionObject[region]?.competition?.[competition]
                    ?.favorite_order ??
                  regionObject[region]?.competition?.[competition]?.order,
              });
            }
          }
        }
        setGames(gamesArray || []);
        setTimeout(() => {
          dispatch(setIsGameDataLoading(false));
        }, 400);
      } else if (data?.rid === GET_GAMES) {
        dispatch(setSubIds({games: data?.data?.subid}));
        let gamesData = [];

        const regionObject =
          Object.values(data?.data?.data?.sport || {})?.[0]?.region || {};

        for (const region in regionObject) {
          for (const competition in regionObject[region]?.competition) {
            for (const currentGame in regionObject[region]?.competition?.[
              competition
            ]?.game) {
              gamesData.push({
                ...regionObject[region]?.competition?.[competition]?.game?.[
                  currentGame
                ],
                competition_name:
                  regionObject[region]?.competition?.[competition]?.name,
                competition_id:
                  regionObject[region]?.competition?.[competition]?.id,
                competition_order:
                  regionObject[region]?.competition?.[competition]
                    ?.favorite_order ??
                  regionObject[region]?.competition?.[competition]?.order,
              });
            }
          }
        }

        setGames(gamesData || []);
        setTimeout(() => {
          dispatch(setIsGameDataLoading(false));
        }, 400);
      } else if (data?.rid === GET_BOOSTED_GAMES) {
        let gamesData = [];
        const sportObject = Object.values(data?.data?.data?.sport)?.find(
          item => item?.alias === sportParam,
        );
        const competitionsObject = Object.values(sportObject?.region || {})?.[0]
          ?.competition;

        for (const competition in competitionsObject) {
          gamesData.push(
            ...Object.values(competitionsObject?.[competition]?.game || {}),
          );
        }
        setGames(gamesData || []);
        setTimeout(() => {
          dispatch(setIsGameDataLoading(false));
        }, 400);
      }
    },
    [dispatch, sportParam],
  );

  useEffect(() => {
    if (!showShowMoreButton) {
      setExpanded(false);
      setSelectedRegion({});
    }
  }, [showShowMoreButton]);

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  useLayoutEffect(() => {
    if (!isGameDataLoading && !isPopularCompetitionsLoading) {
      // setHeight(document?.querySelector?.('.sportsWrapper')?.offsetHeight);
    }
  }, [width, isGameDataLoading, isPopularCompetitionsLoading]);

  const getResultName = useCallback(
    displayKey => {
      let colCount = null;
      for (let i = 0; i < GAMES_DATA?.length; i++) {
        if (!!colCount) {
          break;
        }

        const market = Object.values(GAMES_DATA[i]?.market || {})?.find(
          marketItem => marketItem?.display_key === displayKey,
        );

        if (!!market?.col_count) {
          colCount = market?.col_count;
        }
      }
      // TODO it's written without map because the names Pano wants to change every time
      return (
        <div
          className="justify-end flex items-center full-height rounded-borders"
          style={{
            marginRight: width > BREAKPOINT_XS ? 0 : 5,
            backgroundColor: '#003069',
          }}>
          {displayKey === MARKETS_WINNER ? (
            colCount === 2 ? (
              <span
                className="whiteText bold-600 font-12 text-center text-uppercase"
                style={{width: 145}}>
                {t('matchResult')}
              </span>
            ) : (
              <span
                className="whiteText bold-600 font-12 text-center text-uppercase"
                style={{width: 145}}>
                {t('matchResult')}
              </span>
            )
          ) : displayKey === MARKETS_BOTH_TEAM_TO_SCORE ? (
            <div className="flex column items-center overflow-hidden">
              <span
                className="whiteText bold-600 font-12 text-center ellipsis-2-lines full-width text-uppercase"
                style={{maxWidth: 95}}>
                {t('bothTeamToScoreTitle')}
              </span>
            </div>
          ) : displayKey === MARKETS_DOUBLE_CHANCE ? (
            <span
              className="whiteText bold-600 font-12 text-center text-uppercase"
              style={{width: 145}}>
              {t('doubleChanceFilter')}
            </span>
          ) : displayKey === MARKETS_HANDICAP ? (
            <span
              className="whiteText bold-600 font-12 text-center text-uppercase"
              style={{width: 95}}>
              {t('handicap')}
            </span>
          ) : displayKey === MARKETS_TOTALS ? (
            <span
              className="whiteText bold-600 font-12 text-center text-uppercase"
              style={{width: 95}}>
              {t('total')}
            </span>
          ) : MARKETS_ODD_EVEN ? (
            <span
              className="whiteText bold-600 font-12 text-center text-uppercase"
              style={{width: 95}}>
              {t('oddEven')}
            </span>
          ) : (
            <></>
          )}
        </div>
      );
    },
    [GAMES_DATA, t, width],
  );

  const ArrowIcon = isExpanded ? TopTriangle : DownTriangle;

  const getMarketColsCount = useCallback(
    displayKey => {
      if (
        displayKey === MARKETS_TOTALS ||
        displayKey === MARKETS_HANDICAP ||
        displayKey === MARKETS_ODD_EVEN ||
        displayKey === MARKETS_BOTH_TEAM_TO_SCORE
      ) {
        return 2;
      }
      let colCount = null;
      for (let i = 0; i < GAMES_DATA?.length; i++) {
        if (!!colCount) {
          break;
        }

        const market = Object.values(GAMES_DATA[i]?.market || {})?.find(
          marketItem => marketItem?.display_key === displayKey,
        );

        if (!!market?.col_count) {
          colCount = market?.col_count;
        }
      }
      return colCount;
    },
    [GAMES_DATA],
  );

  const marketsData = useMemo(
    () =>
      DROPDOWN_MARKETS_NAMES?.[sportParam] ||
      DROPDOWN_MARKETS_NAMES?.['OTHER_SPORTS_MARKETS'],
    [sportParam],
  );

  return (
    <div>
      {!!isGameDataLoading && !showLoggedInToSee && <GameSkeletons />}
      {!!showLoggedInToSee && (
        <div className="flex justify-center" style={{height: '100vh'}}>
          <span className="whiteText bold-600  font-20 py-lg text-center">
            {t('toSeeFavouriteGames')}
          </span>
        </div>
      )}
      <div className={`${!!isGameDataLoading && 'hidden'}`}>
        {GAMES_DATA?.length > 0 ? (
          <div className={`${sportGameIdParam && 'row'}`}>
            <div
              className={`gamesDataContainer ${
                // ((!!sportGameIdParam && width <= BREAKPOINT_MD) ||
                //   (showBetSlip &&
                //     !!sportGameIdParam &&
                //     width <= BREAKPOINT_LG)) &&
                width < BREAKPOINT_XS && !!sportGameIdParam && 'hidden'
              }`}
              style={{flex: 1}}>
              <div
                className={`flex row pb-md ${width > BREAKPOINT_XS ? '' : ''}`}>
                <div
                  style={{flexGrow: 1}}
                  className={`row items-center flex-shrink gap-20 ${
                    width < BREAKPOINT_XS ? 'py-xs' : ''
                  }`}>
                  <div
                    className={`row items-center gap-5 full-width ${
                      width < BREAKPOINT_XS ? 'px-sm' : 'justify-between'
                    }`}>
                    {width < BREAKPOINT_XS &&
                      gameFilterParam !== FILTERS_BOOSTED_ODDS_NAME &&
                      !location?.pathname?.includes(
                        `/${FILTERS_LIVE_CALENDAR}`,
                      ) && (
                        <>
                          {/*<AppButton*/}
                          {/*  type={11}*/}
                          {/*  height={32}*/}
                          {/*  className="leaguesButton px-sm py-xs"*/}
                          {/*  onClick={() => {*/}
                          {/*    dispatch(setShowMobileRegionsModal(true));*/}
                          {/*  }}*/}
                          {/*  title={*/}
                          {/*    <div className="flex items-center justify-between gap-5">*/}
                          {/*      <div className="flex items-center gap-5">*/}
                          {/*        {Flags?.[params?.region] && (*/}
                          {/*          <img*/}
                          {/*            alt="flag"*/}
                          {/*            width={20}*/}
                          {/*            src={Flags?.[params?.region]}*/}
                          {/*          />*/}
                          {/*        )}*/}
                          {/*        <span className="text-caption blackText">*/}
                          {/*          {t('league')}*/}
                          {/*        </span>*/}
                          {/*      </div>*/}
                          {/*      <ArrowIcon*/}
                          {/*        width={8}*/}
                          {/*        height={8}*/}
                          {/*        fill="gray"*/}
                          {/*        alt="arrowIcon"*/}
                          {/*        className="flex-shrink"*/}
                          {/*      />*/}
                          {/*    </div>*/}
                          {/*  }*/}
                          {/*/>*/}
                          <div
                            onClick={() => dispatch(setShowSideBar(true))}
                            className="row items-center gap-4 whiteText font-9 bold-600 newLightBlueBackground rounded-borders justify-center"
                            style={{width: 58, height: 32}}>
                            <MenuIcon />
                            <span>{t('league')}</span>
                          </div>
                          <RegionsModal />
                          <div>
                            <AppButton
                              type={16}
                              width={47}
                              height={32}
                              onClick={onReset}
                              disabledWithoutLayer={!isResetDisabled}
                              className={`resetButton flex items-center justify-center ${
                                !isResetDisabled ? 'disabledReset' : ''
                              } ${width < BREAKPOINT_XS ? '' : 'desktop'}`}
                              title={
                                <span className="font-9 bold-600 text-capitalize">
                                  {t('reset').toLowerCase()}
                                </span>
                              }
                            />
                          </div>
                        </>
                      )}

                    {!location?.pathname?.includes(
                      `/${FILTERS_LIVE_CALENDAR}`,
                    ) && (
                      <div
                        className="row items-center rounded-borders cursor-pointer"
                        style={{border: '1px solid var(--appYellow)'}}>
                        <div
                          style={{
                            borderTopLeftRadius: 3,
                            borderBottomLeftRadius: 3,
                            height: width < BREAKPOINT_XS ? 30 : 40,
                          }}
                          onClick={() => setIsGrouped(true)}
                          // style={{borderTopLeftRadius: 5, borderBottomLeftRadius: 5}}
                          className={`${
                            width < BREAKPOINT_XS ? 'font-9 bold-600' : ''
                          } px-md flex items-center justify-center ${
                            isGrouped
                              ? 'yellowBackground blueText'
                              : 'whiteText'
                          }`}>
                          {width < 400 ? (
                            <MenuIcon />
                          ) : (
                            <span>{t('competitions')}</span>
                          )}
                        </div>

                        <div
                          style={{
                            height: width < BREAKPOINT_XS ? 30 : 40,
                            borderTopRightRadius: 3,
                            borderBottomRightRadius: 3,
                          }}
                          className={`${
                            width < BREAKPOINT_XS ? 'font-9 bold-600' : ''
                          } px-md flex items-center justify-center ${
                            !isGrouped
                              ? 'yellowBackground blueText'
                              : 'whiteText'
                          }`}
                          onClick={() => setIsGrouped(false)}>
                          {width < 400 ? (
                            <TimeIcon />
                          ) : (
                            <span>{t('time')}</span>
                          )}
                        </div>
                      </div>
                    )}
                    {((width < BREAKPOINT_XS &&
                      gameFilterParam !== FILTERS_BOOSTED_ODDS_NAME &&
                      !location?.pathname?.includes(
                        `/${FILTERS_LIVE_CALENDAR}`,
                      )) ||
                      (!!sportGameIdParam && width > BREAKPOINT_XS)) && (
                      <div
                        className={width < BREAKPOINT_XS ? 'ml-auto' : ''}
                        style={{
                          minWidth: width < 430 ? 120 : 175,
                        }}>
                        <FixedMarketsFilters
                          isGrouped={
                            location?.pathname?.includes(
                              `/${FILTERS_LIVE_CALENDAR}`,
                            )
                              ? false
                              : isGrouped
                          }
                          setIsGrouped={setIsGrouped}
                        />
                      </div>
                    )}
                  </div>
                </div>
                {width > BREAKPOINT_XS && !sportGameIdParam && (
                  <div className="row gap-30" style={{paddingRight: 18}}>
                    {/*<div className={`sm-hide ${!!getMarketParam && 'hide'}`}>*/}
                    {/*  {getResultName(MARKETS_WINNER)}*/}
                    {/*</div>*/}
                    {/*{isFootball && (*/}
                    {/*  <div*/}
                    {/*    className={`sm-hide ${*/}
                    {/*      (!!getMarketParam ||*/}
                    {/*        (!!sportGameIdParam && width >= BREAKPOINT_MD) ||*/}
                    {/*        (!!showBetSlip &&*/}
                    {/*          width >= BREAKPOINT_XS &&*/}
                    {/*          width < BREAKPOINT_MD)) &&*/}
                    {/*      'hide'*/}
                    {/*    }`}>*/}
                    {/*    {getResultName(MARKETS_DOUBLE_CHANCE)}*/}
                    {/*  </div>*/}
                    {/*)}*/}
                    {/*{isFootball && (*/}
                    {/*  <div*/}
                    {/*    className={`sm-hide ${*/}
                    {/*      (!!getMarketParam ||*/}
                    {/*        (!!sportGameIdParam && width >= BREAKPOINT_MD) ||*/}
                    {/*        (!!showBetSlip &&*/}
                    {/*          width >= BREAKPOINT_XS &&*/}
                    {/*          width < BREAKPOINT_LG)) &&*/}
                    {/*      'hide'*/}
                    {/*    }`}>*/}
                    {/*    {getResultName(MARKETS_BOTH_TEAM_TO_SCORE)}*/}
                    {/*  </div>*/}
                    {/*)}*/}
                    {/*<div*/}
                    {/*  className={`sm-hide ${*/}
                    {/*    (!!getMarketParam ||*/}
                    {/*      (!!sportGameIdParam && width >= BREAKPOINT_MD) ||*/}
                    {/*      (!!showBetSlip &&*/}
                    {/*        !!isFootball &&*/}
                    {/*        width >= BREAKPOINT_XS &&*/}
                    {/*        width < BREAKPOINT_LG)) &&*/}
                    {/*    'hide'*/}
                    {/*  }`}>*/}
                    {/*  {getResultName(MARKETS_HANDICAP)}*/}
                    {/*</div>*/}
                    {/*<div*/}
                    {/*  className={` md-hide sm-hide ${*/}
                    {/*    (!!getMarketParam ||*/}
                    {/*      (!!sportGameIdParam && width > BREAKPOINT_MD) ||*/}
                    {/*      (!!showBetSlip &&*/}
                    {/*        width >= BREAKPOINT_XS &&*/}
                    {/*        width < BREAKPOINT_LG)) &&*/}
                    {/*    'hide'*/}
                    {/*  }`}>*/}
                    {/*  {getResultName(MARKETS_TOTALS)}*/}
                    {/*</div>*/}
                    {/*<div className={`${!getMarketParam && 'hide'}`}>*/}
                    {/*  {getResultName(*/}
                    {/*    (*/}
                    {/*      DROPDOWN_MARKETS_NAMES?.[getParam.get('sport')] ||*/}
                    {/*      DROPDOWN_MARKETS_NAMES?.['OTHER_SPORTS_MARKETS']*/}
                    {/*    )?.find(item => item?.title === getMarketParam)*/}
                    {/*      ?.displayKey,*/}
                    {/*  )}*/}
                    {/*</div>*/}
                  </div>
                )}
                {width > BREAKPOINT_XS && !sportGameIdParam && (
                  <div className="row gap-30" style={{paddingRight: 18}}>
                    {marketsData?.map((item, index) => (
                      <div
                        key={item?.title}
                        className={`sm-hide ${!!getMarketParam && 'hide'} ${
                          width < (showBetSlip ? 1640 : 1320) &&
                          marketsData?.length > 3 &&
                          index === marketsData?.length - 1 &&
                          'hide'
                        } ${
                          width < 1200 &&
                          marketsData?.length > 3 &&
                          index === marketsData?.length - 2 &&
                          'hide'
                        } ${
                          showBetSlip && width < 1450 && index > 2 && 'hide'
                        } ${
                          showBetSlip && width < 1330 && index > 1 && 'hide'
                        } ${
                          showBetSlip && width < 1200 && index > 0 && 'hide'
                        }`}>
                        {getResultName(item?.displayKey)}
                      </div>
                    ))}
                    <div className={`${!getMarketParam && 'hide'}`}>
                      {getResultName(
                        marketsData?.find(
                          item => item?.title === getMarketParam,
                        )?.displayKey,
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div
                className={`flex column gap-5 ${
                  !!sportGameIdParam && width < BREAKPOINT_XS ? 'hidden' : ''
                }`}>
                <div className={`${isGrouped ? 'block' : 'hidden'}`}>
                  {groupedData?.length && (
                    <Virtuoso
                      useWindowScroll
                      data={groupedData}
                      itemContent={(index, item) => (
                        <div className={`${index !== 0 ? 'pt-md' : ''}`}>
                          <AccordionItem
                            defaultExpanded
                            count={item?.length}
                            GAMES_DATA={GAMES_DATA}
                            key={item?.[0]?.competition_id}
                            selectedGameId={sportGameIdParam}
                            region_alias={item?.[0]?.region_alias}
                            competition_id={item?.[0]?.competition_id}
                            onFavouriteSelect={onFavouriteSelect}
                            title={`${item?.[0]?.region_alias} - ${item?.[0]?.competition_name}`}
                            hideResultNames={
                              item?.length === 1 &&
                              item?.[0]?.show_type === 'OUTRIGHT'
                            }>
                            {item?.map((gameItem, index) => (
                              <GameItem
                                isGrouped
                                index={index}
                                key={gameItem?.id}
                                gameItem={gameItem}
                                setGames={setGames}
                                showLineSeparator={item?.length > 1}
                                getMarketColsCount={getMarketColsCount}
                                gameState={gameItem?.info?.current_game_state}
                                current_game_time={
                                  gameItem?.info?.current_game_time
                                }
                                current_game_last_set={
                                  gameItem?.stats?.[
                                    `score_${gameItem?.info?.current_game_state}`
                                  ]
                                }
                              />
                            ))}
                          </AccordionItem>
                        </div>
                      )}
                    />
                  )}
                </div>
                <div
                  className={`${
                    isGrouped ? 'hidden' : 'block rounded-borders'
                  }`}>
                  {GAMES_DATA?.length && (
                    <Virtuoso
                      useWindowScroll
                      data={GAMES_DATA}
                      itemContent={(index, gameItem) => (
                        <GameItem
                          index={index}
                          key={gameItem?.id}
                          gameItem={gameItem}
                          setGames={setGames}
                          isFirst={index === 0}
                          withBackground={index % 2 !== 0}
                          getMarketColsCount={getMarketColsCount}
                          isLast={index === GAMES_DATA?.length - 1}
                          gameState={gameItem?.info?.current_game_state}
                          current_game_time={gameItem?.info?.current_game_time}
                          current_game_last_set={
                            gameItem?.stats?.[
                              `score_${gameItem?.info?.current_game_state}`
                            ]
                          }
                        />
                      )}
                    />
                  )}
                </div>
                <div className="relative">
                  {/*{showShowMoreButton && (*/}
                  {/*  <div*/}
                  {/*    className="rowCenter mt-lg"*/}
                  {/*    {...getToggleProps({*/}
                  {/*      onClick: onShowMore,*/}
                  {/*    })}>*/}
                  {/*    <p className="textCenter cursor-pointer font-15 bold-600 whiteText">*/}
                  {/*      {t('showMore')}*/}
                  {/*    </p>*/}
                  {/*  </div>*/}
                  {/*)}*/}
                  {/*<div className="flex mb-lg justify-center absolute-center z-max showMoreContainer">*/}
                  {/*  <Regions*/}
                  {/*    sportAlias={sportParam}*/}
                  {/*    selectedRegion={selectedRegion}*/}
                  {/*    setSelectedRegion={setSelectedRegion}*/}
                  {/*    gameFilters={MATCH_FILTERS?.[gameFilterParam]?.status}*/}
                  {/*  />*/}
                  {/*  {!!isExpanded && selectedRegion?.id && (*/}
                  {/*    <div className="competitionsWrapper relative scroll-1 whiteBackground">*/}
                  {/*      <CompetitionsWrapper*/}
                  {/*        showMore*/}
                  {/*        setExpanded={setExpanded}*/}
                  {/*        selectedRegion={selectedRegion}*/}
                  {/*        sportItem={{alias: sportParam}}*/}
                  {/*        setSelectedRegion={setSelectedRegion}*/}
                  {/*        gameFilters={MATCH_FILTERS?.[gameFilterParam]?.status}*/}
                  {/*      />*/}
                  {/*    </div>*/}
                  {/*  )}*/}
                  {/*</div>*/}
                </div>
              </div>
            </div>
            {!!sportGameIdParam && (
              <div
                className="scroll-auto-y scroll-1"
                style={{
                  flex: 1,
                  padding: '0px 5px',
                  maxHeight: width > BREAKPOINT_LG ? 1500 : '100%',
                }}>
                <SelectedGame gameId={sportGameIdParam} />
              </div>
            )}
          </div>
        ) : (gameFilterParam === FILTERS_UPCOMING_MATCHES_NAME ||
            gameFilterParam === FILTERS_LIVE_CALENDAR) &&
          !showLoggedInToSee &&
          !isGameDataLoading ? (
          <span className="textCenter font-24 py-xl">{t('noGames')}</span>
        ) : (
          !showLoggedInToSee &&
          !isGameDataLoading && (
            <div className="flex justify-center my-xl">
              <AppButton
                type={2}
                width="30%"
                fontSize={20}
                onClick={onCheckInUpcomingMatches}
                title={t('checkInUpcomingMatches')}
                className="onCheckInUpcomingMatchesButton"
              />
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default memo(Games);
