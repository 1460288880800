import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import StartTime from '../StartTime';

const SoccerGlobalScore = ({game, showLiveInfo}) => {
  const {t} = useTranslation();

  const halfValue = useMemo(() => {
    let gameHalf = game?.info?.current_game_state;
    gameHalf = gameHalf?.replace?.(/set1/g, '1st');
    gameHalf = gameHalf?.replace?.(/set2/g, '2nd');
    gameHalf = gameHalf?.replace?.(/set3/g, 'Extra Time First');
    gameHalf = gameHalf?.replace?.(/set4/g, 'Extra Time Second');

    return gameHalf;
  }, [game?.info?.current_game_state]);

  return (
    <div
      className={`flex column items-center justify-${
        !!game?.is_live || game?.info?.current_game_state === 'finished'
          ? 'center'
          : 'between'
      }`}>
      {game?.info?.current_game_state === 'finished' ? (
        <div></div>
      ) : (
        <StartTime game={game} />
      )}
      <div className="flex row items-center gap-5">
        {!!game?.is_live && (
          <span
            className={`bold-500 font-30 ${
              game?.info?.current_game_state === 'finished'
                ? 'whiteText'
                : 'orangeText'
            }`}>
            {game?.info?.score1}
          </span>
        )}
        <div>
          <div style={{width: 10, height: 2}} className="whiteBackground"></div>
        </div>
        {!!game?.is_live && (
          <span
            className={`bold-500 font-30 ${
              game?.info?.current_game_state === 'finished'
                ? 'whiteText'
                : 'orangeText'
            }`}>
            {game?.info?.score2}
          </span>
        )}
      </div>
      {game?.info?.current_game_state === 'finished' ? (
        <span className="font-12 bold-600 whiteText" style={{marginTop: 10}}>
          {t('finished')}
        </span>
      ) : (
        showLiveInfo &&
        !!game?.is_live &&
        game?.info?.current_game_state !== 'notstarted' && (
          <div className="flex gap-5">
            {game?.info?.current_game_time && (
              <span className="whiteText bold-600 font-14">
                {game?.info?.current_game_time}'
              </span>
            )}
            <span className={`bold-600 font-14 orangeText`}>{halfValue}</span>
            {game?.info?.current_game_time && (
              <span className="whiteText bold-600 font-14">{t('half')}</span>
            )}
          </div>
        )
      )}
      {!game?.is_live && <div></div>}
    </div>
  );
};

export default SoccerGlobalScore;
