export const formatEventName = ({name, team1Name, team2Name}) =>
  name
    ?.replace('W1', team1Name)
    ?.replace('W2', team2Name)
    ?.replace('Team1', team1Name)
    ?.replace('Team2', team2Name)
    ?.replace('Player 1', team1Name)
    ?.replace('Player 2', team2Name)
    ?.replace('Player1', team1Name)
    ?.replace('Player2', team2Name)
    ?.replace('Team 1', team1Name)
    ?.replace('Team 2', team2Name)
    ?.replace('1', team1Name)
    ?.replace('2', team2Name);

export const convertTeamNameToId = ({name, team1Id, team2Id}) =>
  name
    ?.replace('W1', team1Id)
    ?.replace('W2', team2Id)
    ?.replace('Team1', team1Id)
    ?.replace('Team2', team2Id)
    ?.replace('Player 1', team1Id)
    ?.replace('Player 2', team2Id)
    ?.replace('Player1', team1Id)
    ?.replace('Player2', team2Id)
    ?.replace('Team 1', team1Id)
    ?.replace('Team 2', team2Id);

export const eventNamesTypesArray = ['W1', 'W2', 'X', 'draw', 'Draw'];
export const formatResultName = name =>
  name
    ?.replace('W1', 'home')
    ?.replace('W2', 'away')
    ?.replace('X', 'draw')
    ?.replace('draw', 'draw')
    ?.replace('Draw', 'draw')
    .trim?.();

export const formatGameStates = ({sportAlias, gameState}) => {
  let result = gameState;
  if (result) {
    switch (sportAlias) {
      case 'Soccer':
      case 'Futsal':
      case 'Handball':
      case 'RugbyUnion':
      case 'RugbySeven':
      case 'RugbyLeague':
      case 'CyberFootball':
        result = result.replace(/set1/g, '1st Half');
        result = result.replace(/set2/g, '2nd Half');
        result = result.replace(/set3/g, 'Extra Time First Half');
        result = result.replace(/set4/g, 'Extra Time Second Half');
        break;
      case 'Basketball':
      case 'EBasketball':
      case 'AmericanFootball':
      case 'AustralianFootball':
        result = result.replace(/set1/g, '1st Quarter');
        result = result.replace(/set2/g, '2nd Quarter');
        result = result.replace(/set3/g, '3rd Quarter');
        result = result.replace(/set4/g, '4th Quarter');
        break;
      case 'Bowls':
      case 'Tennis':
      case 'Volleyball':
      case 'TableTennis':
      case 'KingOfGlory':
      case 'PistolShooting':
      case 'PistolHead2Head':
      case 'LeagueofLegendsWildRift':
        result = result.replace(/set1/g, '1st Set');
        result = result.replace(/set2/g, '2nd Set');
        result = result.replace(/set3/g, '3rd Set');
        result = result.replace(/set4/g, '4th Set');
        result = result.replace(/set5/g, '5th Set');
        result = result.replace(/set6/g, '6th Set');
        result = result.replace(/set7/g, '7th Set');
        break;
      case 'Baseball':
        result = result.replace(/set1/g, '1st Inning');
        result = result.replace(/set2/g, '2nd Inning');
        result = result.replace(/set3/g, '3rd Inning');
        result = result.replace(/set4/g, '4th Inning');
        result = result.replace(/set5/g, '5th Inning');
        result = result.replace(/set6/g, '6th Inning');
        result = result.replace(/set7/g, '7th Inning');
        result = result.replace(/set8/g, '8th Inning');
        result = result.replace(/set9/g, '9th Inning');
        break;
      case 'Cricket':
        result = result.replace(/set1/g, '1st Innings');
        result = result.replace(/set2/g, '2nd Innings');
        result = result.replace(/set3/g, '3rd Innings');
        result = result.replace(/set4/g, '4th Innings');
        break;
      case 'Hockey':
      case 'IceHockey':
      case 'WaterPolo':
        result = result.replace(/set1/g, '1st Period');
        result = result.replace(/set2/g, '2nd Period');
        result = result.replace(/set3/g, '3rd Period');
        result = result.replace(/set4/g, '4th Period');

        result = result.replace(/set4/g, 'Overtime');
        break;
      case 'Golf':
        result = result.replace(/set1/g, 'Front Nine');
        result = result.replace(/set2/g, 'Back Nine');
        break;
      case 'Archery':
        result = result.replace(/set1/g, '1st Round');
        result = result.replace(/set2/g, '2nd Round');
        result = result.replace(/set3/g, '3rd Round');
        result = result.replace(/set4/g, '4th Round');
        result = result.replace(/set5/g, '5th Round');
        break;
      case 'Dota2':
      case 'Badminton':
      case 'LeagueOfLegends':
        result = result.replace(/set1/g, '1st Game');
        result = result.replace(/set2/g, '2nd Game');
        result = result.replace(/set3/g, '3rd Game');
        result = result.replace(/set4/g, '4th Game');
        result = result.replace(/set5/g, '5th Game');
        break;
      case 'Darts':
        result = result.replace(/set1/g, '1st Leg');
        result = result.replace(/set2/g, '2nd Leg');
        result = result.replace(/set3/g, '3rd Leg');
        result = result.replace(/set4/g, '4th Leg');
        result = result.replace(/set5/g, '5th Leg');
        break;
      case 'Valorant':
        result = result.replace(/set1/g, '1st Map');
        result = result.replace(/set2/g, '2nd Map');
        result = result.replace(/set3/g, '3rd Map');
        result = result.replace(/set4/g, '4th Map');
        result = result.replace(/set5/g, '5th Map');
        break;
      case 'Boxing':
        result = result.replace(/set1/g, 'Round 1');
        result = result.replace(/set2/g, 'Round 2');
        result = result.replace(/set3/g, 'Round 3');
        result = result.replace(/set4/g, 'Round 4');
        result = result.replace(/set5/g, 'Round 5');
        result = result.replace(/set6/g, 'Round 6');
        result = result.replace(/set7/g, 'Round 7');
        result = result.replace(/set8/g, 'Round 8');
        result = result.replace(/set9/g, 'Round 9');
        result = result.replace(/set10/g, 'Round 10');
        result = result.replace(/set11/g, 'Round 11');
        result = result.replace(/set12/g, 'Round 12');
        break;
      case 'Formula1':
      case 'AutoRacing':
        result = result.replace(/set1/g, 'Lap 1');
        result = result.replace(/set2/g, 'Lap 2');
        result = result.replace(/set3/g, 'Lap 3');
        result = result.replace(/set4/g, 'Lap 4');
        result = result.replace(/set5/g, 'Lap 5');
        result = result.replace(/set6/g, 'Lap 6');
        result = result.replace(/set7/g, 'Lap 7');
        result = result.replace(/set8/g, 'Lap 8');
        result = result.replace(/set9/g, 'Lap 9');
        result = result.replace(/set10/g, 'Lap 10');
        result = result.replace(/set11/g, 'Lap 11');
        result = result.replace(/set12/g, 'Lap 12');
        result = result.replace(/set13/g, 'Lap 13');
        result = result.replace(/set14/g, 'Lap 14');
        result = result.replace(/set15/g, 'Lap 15');
        result = result.replace(/set16/g, 'Lap 16');
        result = result.replace(/set17/g, 'Lap 17');
        result = result.replace(/set18/g, 'Lap 18');
        result = result.replace(/set19/g, 'Lap 19');
        result = result.replace(/set20/g, 'Lap 20');
        break;
      case 'BasketballShots':
        result = result.replace(/set1/g, '1st Shot');
        result = result.replace(/set2/g, '2nd Shot');
        result = result.replace(/set3/g, '3rd Shot');
        result = result.replace(/set4/g, '4th Shot');
        result = result.replace(/set5/g, '5th Shot');
        result = result.replace(/set6/g, '6th Shot');
        result = result.replace(/set7/g, '7th Shot');
        result = result.replace(/set8/g, '8th Shot');
        result = result.replace(/set9/g, '9th Shot');
        result = result.replace(/set10/g, '10th Shot');
        break;
      case 'Snooker':
        result = result.replace(/set1/g, '1st Frame');
        result = result.replace(/set2/g, '2nd Frame');
        result = result.replace(/set3/g, '3rd Frame');
        result = result.replace(/set4/g, '4th Frame');
        result = result.replace(/set5/g, '5th Frame');
        result = result.replace(/set6/g, '6th Frame');
        result = result.replace(/set7/g, '7th Frame');
        result = result.replace(/set8/g, '8th Frame');
        result = result.replace(/set9/g, '9th Frame');
        break;
      default:
        break;
    }
  }

  return result?.trim?.()?.replace?.('notstarted', 'notStarted');
};
