import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

const SoccerTitleScore = ({game}) => {
  const {t} = useTranslation();

  const halfValue = useMemo(() => {
    let gameHalf = game?.info?.current_game_state;
    gameHalf = gameHalf?.replace?.(/set1/g, '1st');
    gameHalf = gameHalf?.replace?.(/set2/g, '2nd');
    gameHalf = gameHalf?.replace?.(/set3/g, 'Extra Time First');
    gameHalf = gameHalf?.replace?.(/set4/g, 'Extra Time Second');

    return gameHalf;
  }, [game?.info?.current_game_state]);

  return (
    !!game?.is_live && (
      <div className="flex gap-5 rowCenter">
        {game?.info?.current_game_time &&
          game?.info?.current_game_state !== 'notstarted' && (
            <span className="blueText bold-600 font-10">
              {game?.info?.current_game_time}'
            </span>
          )}
        <div className="rowCenter gap-3">
          <span className={`bold-600 font-10 orangeText`}>{halfValue}</span>
          {game?.info?.current_game_time &&
            game?.info?.current_game_state !== 'notstarted' && (
              <span className="blueText bold-400 font-10">{t('half')}</span>
            )}
        </div>
      </div>
    )
  );
};

export default SoccerTitleScore;
