import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  subIds: {
    games: '',
    sports: '',
    boostedGames: '',
  },
  sports: [],
  searchQuery: '',
  showSideBar: false,
  boostedGameIds: [],
  activeFilterParams: '',
  isGameDataLoading: false,
  isSportDataLoading: false,
  favouriteCompetitionsObject: {},
  isPopularCompetitionsLoading: false,
};

export const sportSlice = createSlice({
  name: 'sport',
  initialState,
  reducers: {
    setSports: (state, {payload}) => {
      state.sports = payload;
    },
    setActiveFilterParams: (state, {payload}) => {
      state.activeFilterParams = payload;
    },
    setIsGameDataLoading: (state, {payload}) => {
      state.isGameDataLoading = payload;
    },
    setIsSportDataLoading: (state, {payload}) => {
      state.isSportDataLoading = payload;
    },
    setIsPopularCompetitionsLoading: (state, {payload}) => {
      state.isPopularCompetitionsLoading = payload;
    },
    setSearchQuery: (state, {payload}) => {
      state.searchQuery = payload;
    },
    setBoostedGameIds: (state, {payload}) => {
      state.boostedGameIds = payload;
    },
    setShowSideBar: (state, {payload}) => {
      state.showSideBar = payload;
    },
    setFavouriteCompetitionsObject: (state, {payload}) => {
      state.favouriteCompetitionsObject = payload;
    },
    setSubIds: (state, {payload}) => {
      return {
        ...state,
        subIds: {
          ...state?.subIds,
          ...payload,
        },
      };
    },
  },
});

export const {
  setSports,
  setSubIds,
  setShowSideBar,
  setSearchQuery,
  setBoostedGameIds,
  setIsGameDataLoading,
  setActiveFilterParams,
  setIsSportDataLoading,
  setFavouriteCompetitionsObject,
  setIsPopularCompetitionsLoading,
} = sportSlice.actions;

export default sportSlice.reducer;
