import React, {useMemo} from 'react';

const TableTennisTitleScore = ({game}) => {
  const halfValue = useMemo(() => {
    let gameHalf = game?.info?.current_game_state;

    gameHalf = gameHalf?.replace?.(/(\D)(\d)/, '$1 $2');

    return gameHalf;
  }, [game?.info?.current_game_state]);

  return (
    !!game?.is_live &&
    game?.info?.current_game_state && (
      <span className={`bold-600 font-10 orangeText`}>{halfValue}</span>
    )
  );
};

export default TableTennisTitleScore;
