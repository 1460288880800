import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useCollapse} from 'react-collapsed';
import {useSearchParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {useSocket} from '../../../../Hooks';

import {
  getIsLoggedIn,
  getWssSocket,
} from '../../../../Redux/AppSlice/AppSelectors';
import {setFavouriteCompetitionsObject} from '../../../../Redux/SportSlice';
import {getFavouriteCompetitionsObject} from '../../../../Redux/SportSlice/SportSelectors';

import RegionSkeletons from '../../../UI/Skeletons/RegionSkeletons';

import {FixedCompetitionsData} from '../../../../Constants/FixedCompetitionsData';
import {FixedCompetitionsFilters} from '../../../../Constants/FixedCompetitionsFilters';
import {
  MATCH_FILTERS,
  FILTERS_LIVE_NAME,
  FILTERS_LIVE_STATUS,
  FILTERS_UPCOMING_MATCHES_NAME,
  FILTERS_UPCOMING_SEVEN_DAYS_STATUS,
  FILTERS_FAVOURITE_COMPETITIONS_NAME,
} from '../../../../Constants/MatchFilters';
import {
  GET_COMPETITIONS,
  GET_FIXED_COMPETITIONS,
} from '../../../../Constants/Socket';
import {Flags} from '../../../../Constants/Flags';

import {ReactComponent as StarIcon} from '../../../../Assets/Icons/Globals/star.svg';
import {ReactComponent as ArrowUpIcon} from '../../../../Assets/Icons/Globals/arrowUp.svg';
import {ReactComponent as TopLeagueIcon} from '../../../../Assets/Icons/Globals/topLeague.svg';
import {ReactComponent as ArrowDownIcon} from '../../../../Assets/Icons/Globals/arrowDown.svg';

const RegionItem = ({
  index,
  regionItem,
  isExpanded,
  selectedIndex,
  setSelectedIndex,
  selectedFilteredIndex,
  isFilteredItem = false,
  setSelectedFilteredIndex,
}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [getParam, setGetParam] = useSearchParams();
  const {getCompetitions, getFixedCompetitions, setFavouriteCompetition} =
    useSocket();

  const wssSocket = useSelector(getWssSocket);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const favouriteCompetitionsObject = useSelector(
    getFavouriteCompetitionsObject,
  );

  const sportParam = useMemo(() => getParam.get('sport'), [getParam]);
  const regionParam = useMemo(() => getParam.get('region'), [getParam]);
  const gameFilterParam = useMemo(() => getParam.get('gameFilter'), [getParam]);

  const [competitions, setCompetitions] = useState([]);
  const [fixedCompetitions, setFixedCompetitions] = useState([]);
  const [isCompetitionsLoading, setIsCompetitionsLoading] = useState(false);

  const {getCollapseProps, getToggleProps} = useCollapse({isExpanded});

  const showTopLeagues = useMemo(
    () =>
      fixedCompetitions?.length > 0 &&
      (gameFilterParam === FILTERS_UPCOMING_MATCHES_NAME ||
        gameFilterParam === FILTERS_LIVE_NAME),
    [fixedCompetitions?.length, gameFilterParam],
  );

  const selectedCompetitionsIds = useMemo(() => {
    const splitArray = regionParam?.split?.('-');
    const competitions =
      splitArray?.map(item => item?.split?.('_')?.slice?.(1)) || [];
    return (competitions || [])?.flat?.(Infinity)?.map?.(item => +item) || [];
  }, [regionParam]);

  const onFavouriteSelect = useCallback(
    competitionId => {
      const newFavouriteCompetitionsObject = {...favouriteCompetitionsObject};
      if (
        Object.keys(newFavouriteCompetitionsObject || {})?.includes(
          `${competitionId}`,
        )
      ) {
        delete newFavouriteCompetitionsObject[`${competitionId}`];
      } else {
        newFavouriteCompetitionsObject[`${competitionId}`] = {};
      }
      dispatch(setFavouriteCompetitionsObject(newFavouriteCompetitionsObject));
      setFavouriteCompetition({idsObject: newFavouriteCompetitionsObject});
    },
    [dispatch, setFavouriteCompetition, favouriteCompetitionsObject],
  );

  const onCompetitionClick = useCallback(
    (regionAlias, competitionId) => {
      const splitArray = !!regionParam ? (regionParam || '')?.split?.('-') : [];

      const findIndex = splitArray.findIndex(item =>
        item?.includes(`${regionAlias}`),
      );

      if (!splitArray?.length) {
        splitArray.push(`${regionAlias}_${competitionId}`);
      } else if (findIndex > -1) {
        const competitions = splitArray?.[findIndex]?.split?.('_');
        const findCompetitionIndexIndex = competitions.indexOf(
          `${competitionId}`,
        );
        if (findCompetitionIndexIndex > -1) {
          competitions.splice(findCompetitionIndexIndex, 1);
        } else {
          competitions.push(`${competitionId}`);
        }
        if (competitions?.length === 1) {
          splitArray?.splice(findIndex, 1);
        } else {
          splitArray[findIndex] = competitions.join('_');
        }
      } else {
        splitArray.push(`${regionAlias}_${competitionId}`);
      }

      if (!!splitArray?.length) {
        getParam.set('region', splitArray.join('-'));
      } else {
        getParam.delete('region');
      }
      setGetParam(getParam);
    },
    [getParam, regionParam, setGetParam],
  );

  const onMessageCb = useCallback(
    event => {
      const data = JSON.parse(event?.data);

      switch (data?.rid) {
        case GET_FIXED_COMPETITIONS:
          if (index === 0 && !isFilteredItem) {
            const fixedCompetitionsArray = [];
            const regionArray = Object.values(data?.data?.data?.region || {});
            for (let i = 0; i < regionArray?.length; i++) {
              const competitionArray = Object.values(
                regionArray?.[i]?.competition || {},
              );
              for (let j = 0; j < competitionArray?.length; j++) {
                fixedCompetitionsArray?.push({
                  regionId: regionArray?.[i]?.id,
                  title: competitionArray?.[j]?.name,
                  regionAlias: regionArray?.[i]?.alias,
                  competitionId: competitionArray?.[j]?.id,
                  firstGameStartTime: Object.values(
                    competitionArray?.[j]?.game || {},
                  )?.sort((a, b) => a?.start_ts - b?.start_ts)?.[0]?.start_ts,
                  logo: `https://statistics.bcapps.site/images/c/m/0/${competitionArray?.[j]?.id}.png`,
                });
              }
            }
            const sortedFixedCompetitionsArray = fixedCompetitionsArray
              ?.sort((a, b) => a?.firstGameStartTime - b?.firstGameStartTime)
              ?.slice?.(0, 8);
            setFixedCompetitions(
              sortedFixedCompetitionsArray ||
                FixedCompetitionsFilters?.[sportParam],
            );
            setTimeout(() => {
              setIsCompetitionsLoading(false);
            }, 400);
          }
          break;
        case GET_COMPETITIONS:
          setCompetitions(
            Object.values(data?.data?.data?.competition || {})?.sort(
              (a, b) => a?.order - b?.order,
            ),
          );
          setTimeout(() => {
            setIsCompetitionsLoading(false);
          }, 400);
          break;
        default:
          break;
      }
    },
    [index, isFilteredItem, sportParam],
  );

  useEffect(() => {
    if (!!isExpanded) {
      setIsCompetitionsLoading(true);
      if (index === 0 && !isFilteredItem) {
        if (FixedCompetitionsData?.[sportParam]) {
          getFixedCompetitions({
            activeSportAlias: sportParam,
            competitionsIdsArray: FixedCompetitionsData?.[sportParam],
            gameFilters:
              gameFilterParam === FILTERS_UPCOMING_MATCHES_NAME
                ? FILTERS_UPCOMING_SEVEN_DAYS_STATUS
                : FILTERS_LIVE_STATUS,
          });
        } else if (gameFilterParam !== FILTERS_LIVE_NAME) {
          setFixedCompetitions(FixedCompetitionsFilters?.[sportParam]);
          setTimeout(() => {
            setIsCompetitionsLoading(false);
          }, 400);
        }
      } else {
        getCompetitions({
          activeSportAlias: sportParam,
          regionAlias: regionItem?.alias,
          gameFilters:
            gameFilterParam === FILTERS_FAVOURITE_COMPETITIONS_NAME
              ? {
                  competition: {
                    id: {
                      '@in': Object.keys(
                        favouriteCompetitionsObject || {},
                      )?.map(item => +item),
                    },
                  },
                }
              : MATCH_FILTERS?.[gameFilterParam]?.status,
        });
      }
    } else {
      setOpen(false);
      setCompetitions([]);
      setIsCompetitionsLoading(false);
    }
  }, [
    index,
    sportParam,
    isExpanded,
    isFilteredItem,
    getCompetitions,
    gameFilterParam,
    regionItem?.alias,
    getFixedCompetitions,
    favouriteCompetitionsObject,
  ]);

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  const ArrowIcon = isExpanded ? ArrowUpIcon : ArrowDownIcon;

  return index === 0 && !isFilteredItem ? (
    <div
      style={{
        border: '2px solid var(--appYellow)',
      }}
      className={`blueBackground rounded-borders mx-md mt-md mb-sm ${
        !showTopLeagues && 'hidden'
      }`}
      {...getToggleProps({
        onClick: () => {
          setSelectedFilteredIndex(null);
          if (selectedIndex === 0) {
            setSelectedIndex(null);
          } else {
            setSelectedIndex(0);
          }
        },
      })}>
      <div
        className="row items-center justify-between yellowBackground px-md "
        style={{height: 48}}>
        <div className="row items-center gap-10">
          <TopLeagueIcon />
          <span className="bold-600 blueText font-15">
            {t('topLeagues')?.toUpperCase()}
          </span>
        </div>
        <div
          style={{width: 24, height: 24, borderRadius: '50%'}}
          className={`darkBlueBackground row items-center justify-center`}>
          <ArrowIcon width={10} fill="var(--white)" />
        </div>
      </div>
      <div {...getCollapseProps()}>
        <div className="flex column gap-5 ma-sm">
          {isCompetitionsLoading ? (
            <RegionSkeletons />
          ) : !!fixedCompetitions?.length ? (
            fixedCompetitions?.map((fixedCompetitionItem, index) => (
              <div
                key={fixedCompetitionItem?.competitionId}
                onClick={e => {
                  e?.preventDefault?.();
                  e?.stopPropagation?.();
                  onCompetitionClick(
                    fixedCompetitionItem?.regionAlias,
                    fixedCompetitionItem?.competitionId,
                  );
                }}
                className="flex row items-center gap-10 px-md py-lg rounded-borders overflow-hidden"
                style={{
                  backgroundColor: selectedCompetitionsIds?.includes(
                    fixedCompetitionItem?.competitionId,
                  )
                    ? 'var(--appYellow)'
                    : 'white',
                }}>
                {!!isLoggedIn && (
                  <div
                    className="flex"
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      onFavouriteSelect(fixedCompetitionItem?.competitionId);
                    }}>
                    <StarIcon
                      fill={
                        Object.keys(
                          favouriteCompetitionsObject || {},
                        )?.includes(`${fixedCompetitionItem?.competitionId}`)
                          ? 'var(--appYellow)'
                          : 'transparent'
                      }
                    />
                  </div>
                )}
                <img
                  height={20}
                  className="competitionIcon"
                  src={fixedCompetitionItem?.logo}
                  alt={fixedCompetitionItem?.title}
                  style={{maxWidth: 30, objectFit: 'contain'}}
                />
                <span className="font-12 blueText bold-600 ellipsis">
                  {fixedCompetitionItem?.title}
                </span>
              </div>
            ))
          ) : (
            <span className="font-13 flex-shrink whiteText">empty</span>
          )}
        </div>
      </div>
    </div>
  ) : (
    <div
      style={{
        border: !!open ? '2px solid var(--appYellow)' : '2px solid transparent',
        backgroundColor: !!open ? 'var(--appYellow)' : '#E6EAEE1A',
      }}
      className={`rounded-borders mx-md my-sm`}
      {...getToggleProps({
        onClick: () => {
          setOpen(prevState => !prevState);
          if (!!isFilteredItem) {
            if (selectedFilteredIndex === index) {
              setSelectedFilteredIndex(null);
            } else {
              setSelectedIndex(null);
              setSelectedFilteredIndex(index);
            }
          } else {
            if (selectedIndex === index) {
              setSelectedIndex(null);
            } else {
              setSelectedFilteredIndex(null);
              setSelectedIndex(index);
            }
          }
        },
      })}>
      <div
        className="row items-center justify-between py-sm rounded-borders mx-md "
        style={{height: 43}}>
        <div className="row items-center gap-10">
          <img
            width={18}
            alt={regionItem?.alias}
            src={Flags?.[regionItem?.alias] || Flags.defaultFlag}
          />
          <span
            className={`bold-600 font-14 ${
              isExpanded ? 'blueText' : 'whiteText'
            }`}>
            {regionItem?.alias}
          </span>
        </div>
        <div
          style={{width: 24, height: 24, borderRadius: '50%'}}
          className={`${
            isExpanded ? 'darkBlueBackground' : 'whiteBackground'
          } row items-center justify-center`}>
          <ArrowIcon
            width={10}
            fill={isExpanded ? 'var(--white)' : 'var(--darkBlue)'}
          />
        </div>
      </div>
      <div {...getCollapseProps()}>
        <div
          style={{backgroundColor: 'var(--appBlue)'}}
          className="pa-sm rounded-borders gap-2 column">
          {isCompetitionsLoading ? (
            <RegionSkeletons />
          ) : !!competitions?.length ? (
            competitions?.map((competitionItem, index) => (
              <div
                key={competitionItem?.id}
                onClick={e => {
                  e?.preventDefault?.();
                  e?.stopPropagation?.();
                  onCompetitionClick(regionItem?.alias, competitionItem?.id);
                }}
                style={{
                  backgroundColor: selectedCompetitionsIds?.includes(
                    competitionItem?.id,
                  )
                    ? 'var(--appYellow)'
                    : 'white',
                }}
                className="flex row items-center gap-5 px-xs paddingVertical-14 rounded-borders">
                {isLoggedIn && (
                  <div
                    className="flex"
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      onFavouriteSelect(competitionItem?.id);
                    }}>
                    <StarIcon
                      fill={
                        Object.keys(
                          favouriteCompetitionsObject || {},
                        )?.includes(`${competitionItem?.id}`)
                          ? 'var(--appYellow)'
                          : 'transparent'
                      }
                    />
                  </div>
                )}
                <div className="row items-center gap-5">
                  <img
                    alt={competitionItem?.name}
                    style={{height: 20, maxWidth: 30, objectFit: 'contain'}}
                    src={`https://statistics.bcapps.site/images/c/m/0/${competitionItem?.id}.png`}
                  />
                  <span
                    className="font-12 bold-600 blueText flex-shrink textWithDotes"
                    style={{maxWidth: 200}}>
                    {competitionItem?.name}
                  </span>
                </div>
              </div>
            ))
          ) : (
            <span className="font-12 bold-600 blueText flex-shrink whiteText">
              empty
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(RegionItem);
