import React, {memo, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import './index.scss';

import {setAuthModal} from '../../../Redux/AppSlice';
import {getUser} from '../../../Redux/UserSlice/UserSelectors';
import {getIsLoggedIn} from '../../../Redux/AppSlice/AppSelectors';

import {useScreenSize, useStorage} from '../../../Hooks';

import {FUN, REAL, SITE_ID} from '../../../Constants/Socket';
import {BREAKPOINT_XS} from '../../../Constants/Globals';
import {CATEGORY_POKER_ID} from '../../../Constants/CasinoMenuFilter';

import {AppButton} from '../../../Components/UI';
import GameActionBarHeader from '../../../Components/Casino/GameActionBar/GameActionBarHeader';
import GameActionBarFooter from '../../../Components/Casino/GameActionBar/GameActionBarFooter';
import MobileHeader from '../../../Components/Header/MobileHeader';
import {useNavigate, useSearchParams} from 'react-router-dom';

import {ReactComponent as CasinoGameBack} from '../../../Assets/Icons/Casino/CasinoGameBack.svg';
import {ReactComponent as FullScreenArrowDown} from '../../../Assets/Icons/Casino/FullScreenArrowDown.svg';
import {ReactComponent as FullScreenArrowTop} from '../../../Assets/Icons/Casino/FullScreenArrowTop.svg';

const Poker = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {width} = useScreenSize();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [isFullScreen, setIsFullScreen] = useState(width < BREAKPOINT_XS);

  const user = useSelector(getUser);
  const isLoggedIn = useSelector(getIsLoggedIn);

  useEffect(() => {
    if (!isLoggedIn) {
      dispatch(setAuthModal({isVisible: true}));
    }
  }, [isLoggedIn]);

  const src = useMemo(
    () =>
      (
        'https://games.pigabet.co.tz/LaunchGame' +
        `?partnerId=${SITE_ID}` +
        '&language=en' +
        '&devicetypeid=1' +
        `&gameId=${CATEGORY_POKER_ID}` +
        '&openType=real' +
        `&token=${user?.auth_token}`
      )?.trim(),
    [user?.auth_token],
  );

  useEffect(() => {
    document.querySelector('body').classList.add('overflow-hidden');
    return () =>
      document.querySelector('body').classList.remove('overflow-hidden');
  }, []);

  return isLoggedIn ? (
    <div
      className="pokerGameContainer blueBackground"
      style={{
        width,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: '100%',
        position: 'fixed',
        zIndex: width > BREAKPOINT_XS ? 100 : 22,
      }}>
      <div
        style={{
          top: 0,
          height: 20,
          position: 'absolute',
        }}
        className="full-width rowCenter">
        {!isMenuOpen && (
          <div onClick={() => setIsMenuOpen(true)} className="cursor-pointer">
            <FullScreenArrowDown
              width={80}
              height={80}
              fill={'rgba(2, 39, 81, 0.3)'}
            />
          </div>
        )}
        {isMenuOpen && (
          <div className="full-width flex column items-center">
            <MobileHeader
              showFilters={false}
              leftIcon={
                <div
                  className="ml-md cursor-pointer"
                  onClick={() => {
                    setIsMenuOpen(false);
                    navigate('/casino/slots');
                  }}>
                  <CasinoGameBack />
                </div>
              }
            />
            <div
              style={{
                top: 10,
                height: 20,
                position: 'absolute',
              }}
              className="cursor-pointer"
              onClick={() => setIsMenuOpen(false)}>
              <FullScreenArrowTop
                width={80}
                height={80}
                fill={'rgba(2, 39, 81, 0.3)'}
              />
            </div>
          </div>
        )}
      </div>
      <iframe
        src={src}
        width="100%"
        height="100%"
        scrolling="no"
        title="casino_game"
      />
    </div>
  ) : (
    <div className="my-xxl">
      <AppButton
        type={1}
        title={t('signInForPlay')}
        onClick={() => dispatch(setAuthModal({isVisible: true}))}
      />
    </div>
  );
};

export default Poker;
