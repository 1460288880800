import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Countdown, {zeroPad} from 'react-countdown';

import './index.scss';

import {getWssSocket} from '../../../../Redux/AppSlice/AppSelectors';

import {MULTIPLE_BET, SINGLE_BET} from '../../../../Constants/Betting';

import {
  formatEventName,
  formatGameStates,
} from '../../../../Utils/EventNamesFormatter';
import {getDateColor} from '../../../../Utils/DateFormatter';
import {checkIsToday, getGameStartTime} from '../../../../Utils/TimeConvertors';

import EventItem from '../../../Home/Games/EventItem';
import SingleBetCalculationsBlock from '../SingleBetCalculationsBlock';

import {ReactComponent as LostIcon} from '../../../../Assets/Icons/Globals/lost.svg';
import {ReactComponent as CloseIcon} from '../../../../Assets/Icons/Globals/close.svg';
import {ReactComponent as CashOutIcon} from '../../../../Assets/Icons/Globals/cashout.svg';
import {ReactComponent as WarningIcon} from '../../../../Assets/Icons/Globals/warning.svg';
import {ReactComponent as DeletedEventIcon} from '../../../../Assets/Icons/Globals/block.svg';
import {ReactComponent as BlockedEventIcon} from '../../../../Assets/Icons/Globals/blockedGame.svg';

import {AppBlinkingLiveIcon, AppLiveIndicator} from '../../../UI';

import {ReactComponent as TimerIcon} from '../../../../Assets/Icons/Globals/timer.svg';

const GameRow = ({
  item,
  subId,
  gameId,
  isLast,
  score1,
  score2,
  eventId,
  isFirst,
  betType,
  is_live,
  marketId,
  gameState,
  isBlocked,
  setBetType,
  eventsCount,
  currentEvent,
  setEventsData,
  allEventsData,
  currentMarket,
  isEventDeleted,
  current_game_time,
  notAllowToBetData,
  removeItemHandler,
  current_game_last_set,
}) => {
  const {t} = useTranslation();

  const wssSocket = useSelector(getWssSocket);

  const [team1score, setTeam1score] = useState(score1);
  const [team2score, setTeam2score] = useState(score2);
  const [isGameLive, setIsGameLive] = useState(is_live);
  const [currentGameState, setCurrentGameState] = useState(gameState);
  const [currentGameLastSet, setCurrentGameLastSet] = useState(
    current_game_last_set,
  );
  const [currentGameTime, setCurrentGameTime] = useState(current_game_time);

  const startTime = useMemo(
    () => getGameStartTime(item?.start_ts),
    [item?.start_ts],
  );

  const isToday = useMemo(() => checkIsToday(item?.start_ts), [item?.start_ts]);

  const minutesBeforeGame = useMemo(
    () => Math.round((new Date(item?.start_ts * 1000) - new Date()) / 1000),
    [item?.start_ts],
  );

  const eventText = useMemo(
    () =>
      `${formatEventName({
        name: currentEvent?.name,
        team1Name: item?.team1_name,
        team2Name: item?.team2_name,
      })} ${
        typeof currentEvent?.base !== 'undefined'
          ? `(${
              currentMarket?.market_type?.toLowerCase()?.includes('handicap') &&
              currentEvent?.base > 0
                ? '+'
                : ''
            }${currentEvent?.base})`
          : ''
      }`,
    [
      item?.team1_name,
      item?.team2_name,
      currentEvent?.name,
      currentEvent?.base,
      currentMarket?.market_type,
    ],
  );

  const showSingleBetCalculations = useMemo(
    () =>
      betType === SINGLE_BET &&
      eventsCount !== 1 &&
      !isEventDeleted &&
      !isBlocked,
    [betType, eventsCount, isBlocked, isEventDeleted],
  );

  const isNotAllowed = useMemo(
    () =>
      notAllowToBetData?.includes(gameId) &&
      betType !== SINGLE_BET &&
      eventsCount > 1,
    [betType, eventsCount, gameId, notAllowToBetData],
  );

  useEffect(() => {
    if (eventsCount > 1) {
      setBetType(MULTIPLE_BET);
    }
  }, []);

  const onMessageCb = useCallback(
    event => {
      const data = JSON.parse(event?.data)?.data;

      const changedGame = data?.[subId]?.game?.[gameId];
      const changedEvent = changedGame?.market?.[marketId]?.event?.[eventId];

      if (data?.[subId]) {
        if (changedEvent?.price) {
          setEventsData(prev => {
            const prevState = [...(prev || [])];
            const foundGame = prevState?.find(
              game => game?.unique_id === eventId,
            );
            if (foundGame) {
              prevState.find(game => game.unique_id === eventId).market[
                marketId
              ].event[eventId] = {
                ...foundGame.market[marketId].event[eventId],
                lastPrice:
                  typeof foundGame?.market?.[marketId]?.event?.[eventId]
                    ?.lastPrice === 'undefined'
                    ? foundGame.market[marketId].event[eventId]?.price
                    : foundGame?.market?.[marketId]?.event?.[eventId]
                        ?.lastPrice,
                isEventDeleted: false,
                ...changedEvent,
              };
            }
            return prevState;
          });
        }
        if (changedGame === null || changedGame?.market?.[marketId] === null) {
          setEventsData(prev => {
            const prevState = [...(prev || [])];
            const foundGame = prevState?.find(
              game => game?.unique_id === eventId,
            );

            if (foundGame) {
              prevState.find(game => game.unique_id === eventId).market[
                marketId
              ].event[eventId] = {
                ...foundGame.market[marketId].event[eventId],
                lastPrice:
                  typeof foundGame?.market?.[marketId]?.event?.[eventId]
                    ?.lastPrice === 'undefined'
                    ? foundGame.market[marketId].event[eventId]?.price
                    : foundGame?.market?.[marketId]?.event?.[eventId]
                        ?.lastPrice,
                isEventDeleted: true,
                ...changedEvent,
              };
            }
            return prevState;
          });
        }
        if (typeof changedGame?.is_blocked !== 'undefined') {
          setEventsData(prev => {
            const prevState = [...(prev || [])];
            const foundGame = prevState?.find(
              game => game?.unique_id === eventId,
            );

            if (foundGame) {
              prevState.find(game => game.unique_id === eventId).is_blocked =
                !!changedGame?.is_blocked;
            }
            return prevState;
          });
        }
        if (typeof changedGame?.info?.current_game_state !== 'undefined') {
          setCurrentGameState(changedGame?.info?.current_game_state);
        }
        if (typeof changedGame?.is_live !== 'undefined') {
          setIsGameLive(!!changedGame?.is_live);
        }
        if (
          typeof changedGame?.info?.score1 !== 'undefined' &&
          !!changedGame?.info?.score1
        ) {
          setTeam1score(changedGame?.info?.score1);
        }
        if (typeof changedGame?.info?.current_game_time !== 'undefined') {
          setCurrentGameTime(changedGame?.info?.current_game_time);
        }
        if (
          typeof changedGame?.info?.score2 !== 'undefined' &&
          !!changedGame?.info?.score2
        ) {
          setTeam2score(changedGame?.info?.score2);
        }
        if (
          typeof changedGame?.stats?.[`score_${currentGameState}`] !==
          'undefined'
        ) {
          setCurrentGameLastSet(prevState => ({
            ...prevState,
            ...changedGame?.stats?.[`score_${currentGameState}`],
          }));
        }
      }
    },
    [subId, gameId, marketId, eventId, currentGameState, setEventsData],
  );

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  const sportIcon = useMemo(() => {
    let src = null;
    try {
      src = require(
        `../../../../Assets/Icons/Sport/${
          item?.sport_alias || item?.sport_index
        }.svg`,
      );
    } catch (error) {}
    return src;
  }, [item?.sport_alias, item?.sport_index]);

  return (
    <div className="relative">
      <div className="appBetSlipGameRow">
        <div
          className={`gameInfoContainer row gap-5 justify-between full-width pt-sm px-md pb-md ${
            isFirst ? 'rounded-top-borders' : ''
          }`}>
          <div className="column overflow-hidden">
            <div className="row items-center gap-5">
              {isGameLive && !isEventDeleted && (
                <div className="row items-center gap-10">
                  <AppBlinkingLiveIcon />
                  <AppLiveIndicator type={2} />
                </div>
              )}
              {/*{!!currentMarket?.cashout && (*/}
              {/*  <CashOutIcon width={15} height={15} />*/}
              {/*)}*/}
              {isNotAllowed && (
                <WarningIcon width={15} height={15} fill="var(--appYellow)" />
              )}
              {/*<CloseIcon*/}
              {/*  width={15}*/}
              {/*  height={15}*/}
              {/*  fill="var(--appRed)"*/}
              {/*  className="cursor-pointer z-index-1"*/}
              {/*  onClick={() => removeItemHandler(eventId)}*/}
              {/*/>*/}
              {isGameLive ? (
                !isEventDeleted && (
                  <div className="row items-center gap-5 text-caption">
                    {currentGameTime && currentGameTime !== 'undefined' && (
                      <span className="blueText bold-600 font-10">
                        {currentGameTime?.trim?.()}'
                      </span>
                    )}
                    {/*{!!team1score &&*/}
                    {/*  !!team2score &&*/}
                    {/*  typeof team1score !== 'undefined' &&*/}
                    {/*  typeof team2score !== 'undefined' && (*/}
                    {/*    <div className="text-caption bold-600 row items-center">*/}
                    {/*      <span className="orangeText">{team1score}</span>*/}
                    {/*      <span className="grayText">-</span>*/}
                    {/*      <span className="orangeText">{team2score}</span>*/}
                    {/*    </div>*/}
                    {/*  )}*/}
                    {/*{item?.info?.set_count !== 1 &&*/}
                    {/*  !currentGameTime &&*/}
                    {/*  typeof currentGameLastSet?.team1_value !==*/}
                    {/*    'undefined' && (*/}
                    {/*    <span className="orangeText bold-600">*/}
                    {/*      {currentGameLastSet?.team1_value}-*/}
                    {/*      {currentGameLastSet?.team2_value}*/}
                    {/*    </span>*/}
                    {/*  )}*/}
                    {currentGameState &&
                      typeof currentGameState !== 'undefined' && (
                        <span className="orangeText bold-700 font-10">
                          {t(
                            formatGameStates({
                              gameState: currentGameState,
                              sportAlias: item?.sport_alias,
                            }),
                          )}
                        </span>
                      )}
                  </div>
                )
              ) : (
                <div>
                  {isToday &&
                  !item?.is_live &&
                  !!minutesBeforeGame &&
                  +minutesBeforeGame < 3600 ? (
                    <div className="flex items-center gap-5 grayText">
                      <TimerIcon fill="var(--gray)" width={15} height={15} />
                      <span className="text-caption-small">
                        {/*<span>{t('startingIn')}</span>*/}
                        <Countdown
                          date={Date.now() + +minutesBeforeGame * 1000}
                          renderer={({hours, minutes, seconds, completed}) => (
                            <span className="blueText bold-700">
                              {zeroPad(minutes)}{' '}
                            </span>
                          )}
                        />
                        <span className="blueText bold-700">{t('M')}</span>
                        {/*<span style={getDateColor(startTime)} className="bold-700">*/}
                        {/*  {startTime?.split('-')?.[1]}*/}
                        {/*</span>*/}
                      </span>
                    </div>
                  ) : (
                    <span
                      className="blackText bold-500 text-capitalize font-14"
                      // style={getDateColor(startTime)}
                    >
                      {startTime?.toLowerCase()}
                    </span>
                  )}
                </div>
              )}
            </div>
            <div className="row items-center gap-7 teamNamesContainer full-width overflow-hidden">
              {(item?.sport_alias || item?.sport_index) && !!sportIcon && (
                <img
                  width={20}
                  src={sportIcon}
                  className="flex-shrink"
                  alt={item?.sport_alias}
                />
              )}
              <div className="column blueText bold-700 font-11 overflow-hidden ">
                <div className="flex items-center">
                  <img
                    width={10}
                    height={10}
                    alt={item?.team1_id}
                    src={`https://statistics.bcapps.site/images/e/m/${Math.floor(
                      item?.team1_id / 2000,
                    )}/${item?.team1_id}.png`}
                  />
                  <span
                    className={`ellipsis ml-xs bold-${
                      +team1score > +team2score ? '700' : '600'
                    } blueText font-12`}>
                    {item?.team1_name}
                  </span>
                </div>
                {item?.team2_name && (
                  <div className="flex items-center">
                    <img
                      width={10}
                      height={10}
                      alt={item?.team2_id}
                      src={`https://statistics.bcapps.site/images/e/m/${Math.floor(
                        item?.team2_id / 2000,
                      )}/${item?.team2_id}.png`}
                    />
                    <span
                      className={`ellipsis ml-xs bold-${
                        +team2score > +team1score ? '700' : '600'
                      } blueText font-12 `}>
                      {item?.team2_name}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="column items-end justify-center flex-shrink z-index-10">
            <LostIcon
              width={22}
              height={22}
              className="cursor-pointer"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                removeItemHandler(eventId);
              }}
            />
          </div>
        </div>
        <div
          className={`eventInfoContainer column pa-md ${
            isLast ? 'rounded-bottom-borders' : ''
          } ${!isLast ? '' : ''}`}>
          <div className="row items-center justify-between gap-5">
            <div className="column overflow-hidden">
              <span className="font-8 blueText">
                {/*{formatEventName({*/}
                {/*  name: currentMarket?.name,*/}
                {/*  team1Name: item?.team1_name,*/}
                {/*  team2Name: item?.team2_name,*/}
                {/*})}*/}
                {t('yourPick')}
              </span>
              <div className="row items-center gap-5 overflow-hidden">
                <img
                  width={10}
                  height={10}
                  alt={item?.team1_id}
                  src={`https://statistics.bcapps.site/images/e/m/${Math.floor(
                    item?.team1_id / 2000,
                  )}/${item?.team1_id}.png`}
                />
                <span className="font-12 blueText bold-600 ellipsis">
                  {eventText}
                </span>
              </div>
            </div>
            <div className="flex gap-3">
              {currentEvent?.lastPrice &&
                currentEvent?.lastPrice !== currentEvent?.price &&
                !isEventDeleted &&
                !isBlocked && (
                  <EventItem
                    betSlip
                    oldPrice
                    disabled
                    type={3}
                    price={currentEvent?.lastPrice}
                  />
                )}
              <EventItem
                betSlip
                disabled
                type={1}
                isBlocked={isBlocked}
                price={currentEvent?.price}
              />
            </div>
          </div>
          {showSingleBetCalculations && (
            <SingleBetCalculationsBlock
              eventId={eventId}
              marketId={marketId}
              allEventsData={allEventsData}
              setEventsData={setEventsData}
              eventOdd={currentEvent?.price}
            />
          )}
          {!!isEventDeleted && (
            <div className="row items-center gap-5 py-sm">
              <DeletedEventIcon fill="red" width={15} height={15} />
              <span className="text-caption grayText redText bold-600">
                {t('eventHasBeenDeleted')}
              </span>
            </div>
          )}
          {!!isBlocked && !isEventDeleted && (
            <div className="row items-center gap-5 py-sm">
              <BlockedEventIcon fill="red" width={15} height={15} />
              <span className="text-caption grayText redText bold-600">
                {t('eventHasBeenBlocked')}
              </span>
            </div>
          )}
        </div>
        {(isEventDeleted || isBlocked) && (
          <div
            className={`absolute deletedEventLayer redText ${
              isFirst
                ? 'rounded-top-borders'
                : isLast
                ? 'rounded-bottom-borders'
                : ''
            }`}
          />
        )}
      </div>
    </div>
  );
};

export default memo(GameRow);
