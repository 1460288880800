import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useParams, useSearchParams} from 'react-router-dom';

import './index.scss';

import {useScreenSize} from '../../../Hooks';
import useCasino from '../../../Hooks/UseCasino';

import {AppButton, AppInput} from '../../UI';
import RegionSkeletons from '../../UI/Skeletons/RegionSkeletons';

import {
  PROVIDER_ICONS,
  ACTIVE_PROVIDER_ICONS,
} from '../../../Constants/ProviderIcons';
import {
  ICONS_SHOW_TYPE,
  NAMES_SHOW_TYPE,
  PROVIDERS_ALL_GAMES_NAME,
  SUB_CATEGORIES_LAST_PLAYED,
} from '../../../Constants/CasinoMenuFilter';

import {ReactComponent as LeftArrowIcon} from '../../../Assets/Icons/Casino/LeftArrow.svg';
import {ReactComponent as RightArrowIcon} from '../../../Assets/Icons/Casino/rightArrow.svg';
import {ReactComponent as ProvidersIcons} from '../../../Assets/Icons/Casino/ProvidersIcons.svg';
import {ReactComponent as ProvidersNames} from '../../../Assets/Icons/Casino/ProvidersNames.svg';
import {ReactComponent as SearchIcon} from '../../../Assets/Icons/Globals/search.svg';
import {BREAKPOINT_XS} from '../../../Constants/Globals';
import {setShowCasinoSideBar} from '../../../Redux/CasinoSlice';
import {useDispatch, useSelector} from 'react-redux';
import {getShowCasinoSideBar} from '../../../Redux/CasinoSlice/CasinoSelectors';

const SideBarProviders = ({isVisible = false, closeHandler}) => {
  const params = useParams();
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const {width} = useScreenSize();
  const [getParam, setGetParam] = useSearchParams();

  const {getProviders, getLiveCasinoSubCategories} = useCasino();

  const showCasinoSideBar = useSelector(getShowCasinoSideBar);

  const subCategoryIdParam = useMemo(
    () => getParam.get('subCategoryId'),
    [getParam],
  );
  const providerParam = useMemo(() => getParam.get('provider'), [getParam]);

  const [providers, setProviders] = useState([]);
  const [showType, setShowType] = useState(NAMES_SHOW_TYPE);
  const [inputValue, setInputValue] = useState('');
  const [providersLoading, setProvidersLoading] = useState(false);

  const splitParam = useMemo(
    () => providerParam?.split?.(','),
    [providerParam],
  );

  const isIconsView = useMemo(() => showType === ICONS_SHOW_TYPE, [showType]);

  const filteredProviders = useMemo(
    () =>
      inputValue?.length > 0
        ? providers?.filter(
            item =>
              item?.name
                ?.toLowerCase()
                ?.includes(inputValue?.trim?.()?.toLowerCase()) ||
              item?.title
                ?.toLowerCase()
                ?.includes(inputValue?.trim?.()?.toLowerCase()),
          )
        : [],
    [inputValue, providers],
  );

  const onProviderClick = useCallback(
    (e, providerName) => {
      e?.preventDefault?.();
      e?.stopPropagation?.();

      if (providerName === PROVIDERS_ALL_GAMES_NAME) {
        getParam.set('provider', providerName);
        setGetParam(getParam);
      } else {
        let splitProviders = splitParam;
        const index = splitProviders.findIndex(item => item === providerName);

        if (providerParam === PROVIDERS_ALL_GAMES_NAME) {
          splitProviders = [providerName];
        } else if (index > -1) {
          splitProviders.splice(index, 1);
        } else {
          splitProviders.push(providerName);
        }

        const newString = splitProviders.join(',');

        getParam.set(
          'provider',
          newString?.length > 0 ? newString : PROVIDERS_ALL_GAMES_NAME,
        );
        setGetParam(getParam);
      }
    },
    [getParam, providerParam, setGetParam, splitParam],
  );

  useEffect(() => {
    if (subCategoryIdParam === SUB_CATEGORIES_LAST_PLAYED) {
      return;
    }

    setProvidersLoading(true);
    if (params?.category === 'slots') {
      getProviders({categoryId: subCategoryIdParam})
        ?.then(res => {
          setProviders(res?.data?.providers);
        })
        ?.finally(() => {
          setTimeout(() => {
            setProvidersLoading(false);
          }, 300);
        });
    } else {
      getLiveCasinoSubCategories({extra_category_id: subCategoryIdParam})
        ?.then(res => {
          setProviders(res?.data?.providers);
        })
        ?.finally(() => {
          setTimeout(() => {
            setProvidersLoading(false);
          }, 300);
        });
    }
    if (!providerParam) {
      getParam.set('provider', PROVIDERS_ALL_GAMES_NAME);
      setGetParam(getParam);
    }
  }, [subCategoryIdParam, params?.category]);

  return (
    (isVisible || width >= BREAKPOINT_XS) &&
    (showCasinoSideBar || isVisible ? (
      <div className="casinoSideBarProvidersContainer overflow-hidden blueBackground">
        {providersLoading ? (
          <div className="loaderContainer">
            <RegionSkeletons />
          </div>
        ) : (
          <>
            <div className="row items-center pt-sm gap-5 actionBar">
              <AppInput
                search
                type={4}
                height={40}
                withoutFullWidth
                inputValue={inputValue}
                onChange={setInputValue}
                className="searchProvider"
                placeholder={t('searchProvider')}
              />
              <AppButton
                type={16}
                width={80}
                height={40}
                className={`resetButton`}
                onClick={e => {
                  setInputValue('');
                  onProviderClick(e, PROVIDERS_ALL_GAMES_NAME);
                }}
                disabled={
                  providerParam &&
                  providerParam === PROVIDERS_ALL_GAMES_NAME &&
                  inputValue?.trim()?.length === 0
                }
                title={
                  <span className="bold-600">{`${
                    providerParam && providerParam !== PROVIDERS_ALL_GAMES_NAME
                      ? `(${providerParam?.split?.(',')?.length})`
                      : ''
                  } ${t('resetLower')}`}</span>
                }
              />
              <div
                onClick={() => {
                  typeof closeHandler !== 'undefined'
                    ? closeHandler()
                    : dispatch(setShowCasinoSideBar(false));
                }}
                className="whiteBackground rowCenter cursor-pointer arrowContainer">
                <LeftArrowIcon />
              </div>
            </div>
            <div
              className={`providersContainer scroll-auto-y scroll-3`}
              style={{
                height: 'calc(100dvh - 98px)',
              }}>
              {filteredProviders?.length > 0 && (
                <div style={{}} className="filteredProvidersContainer">
                  <div className="flex items-center  gap-7 mb-md">
                    <SearchIcon width={10} height={10} fill="var(--white)" />
                    <span className="whiteText bold-600 font-11">{`${
                      filteredProviders?.length
                    }  ${t('resultsFound')}`}</span>
                  </div>
                  <div
                  // style={{maxHeight: 150}}
                  // className="scroll-auto-y scroll-3"
                  >
                    <div
                      className={`flex gap-5 ${
                        isIconsView ? 'wrap' : 'column'
                      }`}>
                      {filteredProviders?.map(providerItem =>
                        !isIconsView ? (
                          <div
                            key={`${showType}_${providerItem?.name}`}
                            className="cursor-pointer providerListItemContainer"
                            onClick={e =>
                              onProviderClick(e, providerItem?.name)
                            }
                            style={{
                              backgroundColor: splitParam?.includes(
                                providerItem?.name,
                              )
                                ? 'var(--appYellow)'
                                : 'var(--newLightBlue)',
                            }}>
                            <span
                              className={`bold-500 font-14 ${
                                splitParam?.includes(providerItem?.name)
                                  ? 'blueText'
                                  : 'whiteText'
                              }`}>
                              {providerItem?.title}
                            </span>
                          </div>
                        ) : (
                          <div
                            onClick={e =>
                              onProviderClick(e, providerItem?.name)
                            }
                            className="rowCenter providerIconItemContainer cursor-pointer"
                            style={{
                              backgroundColor: splitParam?.includes(
                                providerItem?.name,
                              )
                                ? 'var(--appYellow)'
                                : '#003069',
                            }}>
                            <img
                              className="img"
                              alt={providerItem?.name}
                              src={
                                splitParam?.includes(providerItem?.name)
                                  ? ACTIVE_PROVIDER_ICONS?.[providerItem?.name]
                                  : PROVIDER_ICONS?.[providerItem?.name]
                              }
                            />
                          </div>
                        ),
                      )}
                    </div>
                  </div>
                </div>
              )}
              <div
                className="yellowBackground flex row items-center justify-between showTypesContainer"
                // style={{marginTop: filteredProviders?.length > 0 ? 13 : 0}}
              >
                <span className="font-15 blueText bold-600">
                  {t(
                    location?.pathname?.includes('slots')
                      ? 'slotProviders'
                      : 'liveCasinoProviders',
                  )}
                </span>
                <div className="flex row items-center gap-5">
                  <div
                    onClick={() => setShowType(ICONS_SHOW_TYPE)}
                    className="rowCenter cursor-pointer rounded-borders showTypeIcon"
                    style={{
                      border: isIconsView ? '1px solid var(--appBlue)' : 'none',
                      backgroundColor: isIconsView
                        ? 'var(--white)'
                        : 'var(--appYellow)',
                    }}>
                    <ProvidersIcons />
                  </div>
                  <div
                    onClick={() => setShowType(NAMES_SHOW_TYPE)}
                    className="rowCenter cursor-pointer rounded-borders showTypeIcon"
                    style={{
                      backgroundColor: !isIconsView
                        ? 'var(--white)'
                        : 'var(--appYellow)',
                      border: !isIconsView
                        ? '1px solid var(--appBlue)'
                        : 'none',
                    }}>
                    <ProvidersNames />
                  </div>
                </div>
              </div>
              <div
                className={`yellowBorder ${
                  filteredProviders?.length === 0 &&
                  'blueBackground providersListContainer'
                }`}>
                <div
                  className={`flex blueBackground wrapper gap-5 ${
                    !isIconsView ? 'column' : 'row wrap'
                  }`}>
                  {/*{isIconsView ? (*/}
                  {/*  <div*/}
                  {/*    onClick={e =>*/}
                  {/*      onProviderClick(e, PROVIDERS_ALL_GAMES_NAME)*/}
                  {/*    }*/}
                  {/*    className="rowCenter providerIconItemContainer cursor-pointer"*/}
                  {/*    style={{*/}
                  {/*      backgroundColor: splitParam?.includes(*/}
                  {/*        PROVIDERS_ALL_GAMES_NAME,*/}
                  {/*      )*/}
                  {/*        ? 'var(--appYellow)'*/}
                  {/*        : '#003069',*/}
                  {/*    }}>*/}
                  {/*    <img*/}
                  {/*      className="allIcon"*/}
                  {/*      alt={PROVIDERS_ALL_GAMES_NAME}*/}
                  {/*      src={*/}
                  {/*        splitParam?.includes(PROVIDERS_ALL_GAMES_NAME)*/}
                  {/*          ? ACTIVE_PROVIDER_ICONS?.[PROVIDERS_ALL_GAMES_NAME]*/}
                  {/*          : PROVIDER_ICONS?.[PROVIDERS_ALL_GAMES_NAME]*/}
                  {/*      }*/}
                  {/*    />*/}
                  {/*  </div>*/}
                  {/*) : (*/}
                  {/*  <div*/}
                  {/*    onClick={e =>*/}
                  {/*      onProviderClick(e, PROVIDERS_ALL_GAMES_NAME)*/}
                  {/*    }*/}
                  {/*    className="cursor-pointer allProvidersContainer"*/}
                  {/*    style={{*/}
                  {/*      backgroundColor: splitParam?.includes(*/}
                  {/*        PROVIDERS_ALL_GAMES_NAME,*/}
                  {/*      )*/}
                  {/*        ? 'var(--appYellow)'*/}
                  {/*        : 'var(--newLightBlue)',*/}
                  {/*      width: !isIconsView ? '100%' : 130,*/}
                  {/*    }}>*/}
                  {/*    <span*/}
                  {/*      className={`bold-600 font-12 ${*/}
                  {/*        splitParam?.includes(PROVIDERS_ALL_GAMES_NAME)*/}
                  {/*          ? 'blueText'*/}
                  {/*          : 'whiteText'*/}
                  {/*      }`}>*/}
                  {/*      {t('allProviders')}*/}
                  {/*    </span>*/}
                  {/*  </div>*/}
                  {/*)}*/}
                  {providers?.map(providerItem =>
                    !isIconsView ? (
                      <div
                        key={`${showType}_${providerItem?.name}`}
                        className="cursor-pointer providerListItemContainer"
                        onClick={e => onProviderClick(e, providerItem?.name)}
                        style={{
                          backgroundColor: splitParam?.includes(
                            providerItem?.name,
                          )
                            ? 'var(--appYellow)'
                            : 'var(--newLightBlue)',
                        }}>
                        <span
                          className={`bold-500 font-14 ${
                            splitParam?.includes(providerItem?.name)
                              ? 'blueText'
                              : 'whiteText'
                          }`}>
                          {providerItem?.title}
                        </span>
                      </div>
                    ) : (
                      <div
                        onClick={e => onProviderClick(e, providerItem?.name)}
                        className="rowCenter providerIconItemContainer cursor-pointer"
                        style={{
                          backgroundColor: splitParam?.includes(
                            providerItem?.name,
                          )
                            ? 'var(--appYellow)'
                            : '#003069',
                        }}>
                        <img
                          className="img"
                          alt={providerItem?.name}
                          src={
                            splitParam?.includes(providerItem?.name)
                              ? ACTIVE_PROVIDER_ICONS?.[providerItem?.name]
                              : PROVIDER_ICONS?.[providerItem?.name]
                          }
                        />
                      </div>
                    ),
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    ) : (
      <></>
    ))
  );
};

export default SideBarProviders;
