import React, {
  memo,
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';

import './index.scss';

import {useScreenSize, useSocket, useStorage} from '../../../Hooks';

import Header from './Header';
import FiltersList from './FiltersList';
import MarketsList from './MarketsList';
import MobileGameStats from './MobileGameStats';
import GameStatsSkeleton from '../../UI/Skeletons/GameStatsSkeleton';
import SelectedGameSkeleton from '../../UI/Skeletons/SelectedGameSkeleton';

import {
  getLanguage,
  getWssSocket,
  getIsLoggedIn,
} from '../../../Redux/AppSlice/AppSelectors';
import {getSportsArray} from '../../../Redux/SportSlice/SportSelectors';

import {getGameStartTime} from '../../../Utils/TimeConvertors';

import {BREAKPOINT_XS} from '../../../Constants/Globals';
import {GET_SPECIFIC_GAME} from '../../../Constants/Socket';
import {
  MATCH_FILTERS,
  FILTERS_TODAY_NAME,
  FOOTBALL_ALIAS,
} from '../../../Constants/MatchFilters';

import {ReactComponent as SingleGameBack} from '../../../Assets/Icons/Globals/SingleGameBack.svg';
import {SportIcons} from '../../../Constants/SportIcons';
import {Flags} from '../../../Constants/Flags';
import LiveTransaction from './LiveTransaction';
import {
  TRANSACTION_FILTER,
  SINGLE_GAME_STATICS_FILTERS,
} from '../../../Constants/SingleGameStatisticsFilters';
import {AppInput, AppSelect} from '../../UI';
import {useTranslation} from 'react-i18next';
import {FIXED_MARKETS_FILTERS} from '../../../Constants/FixedMarketFiltersArray';
const SelectedGame = () => {
  const params = useParams();
  const navigate = useNavigate();
  const {width} = useScreenSize();
  const [getParam, setGetParam] = useSearchParams();

  const {t} = useTranslation();

  const {getFavouriteMarketsFromStorage} = useStorage();
  const {getSpecificGame, unSubscribeLiveEvents} = useSocket();

  const language = useSelector(getLanguage);
  const wssSocket = useSelector(getWssSocket);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const sportsArray = useSelector(getSportsArray);

  const [data, setData] = useState({});
  const [stats, setStats] = useState([]);
  const [subId, setSubId] = useState(null);
  const [textInfo, setTextInfo] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeGroupId, setActiveGroupId] = useState(1);
  const [isStatsLoading, setIsStatsLoading] = useState(false);
  const [currentGameTime, setCurrentGameTime] = useState(null);
  const [favouriteMarkets, setFavouriteMarkets] = useState([]);
  const [selectedStatsFilter, setSelectedStatsFilter] =
    useState(TRANSACTION_FILTER);

  const sportParam = useMemo(() => getParam.get('sport'), [getParam]);
  const gameFilterParam = useMemo(() => getParam.get('gameFilter'), [getParam]);

  const sportGameIdParam = useMemo(
    () => getParam.get('sportGameId'),
    [getParam],
  );

  const sport = useMemo(
    () => Object.values(data?.sport || {})?.[0],
    [data?.sport],
  );

  const activeSportCount = useMemo(
    () => sportsArray?.find(item => item?.alias === sportParam)?.game,
    [sportParam, sportsArray],
  );

  const region = useMemo(
    () => Object.values(sport?.region || {})?.[0],
    [sport?.region],
  );

  const competition = useMemo(
    () => Object.values(region?.competition || {})?.[0],
    [region?.competition],
  );

  const game = useMemo(
    () => Object.values(competition?.game || {})?.[0],
    [competition?.game],
  );

  const startTime = useMemo(
    () => getGameStartTime(game?.start_ts),
    [game?.start_ts],
  );

  const textInfoLabel = useMemo(
    () => textInfo?.replace(';', '')?.replace('HT', ''),
    [textInfo],
  );

  const ALL_MARKETS_ARRAY = useMemo(
    () =>
      Object.values(game?.market || {})?.sort((a, b) => a?.order - b?.order),
    [game?.market],
  );

  const GROUP_IDS_ARRAY = useMemo(
    () => [
      ...new Set(
        ALL_MARKETS_ARRAY.map(item => item?.group_id && item?.group_id),
      ),
    ],
    [ALL_MARKETS_ARRAY],
  );

  const GROUP_DATA = useMemo(() => {
    const groupedFilters = GROUP_IDS_ARRAY?.map(item => {
      const filteredData = ALL_MARKETS_ARRAY?.filter(
        marketItem => marketItem?.group_id === item && item,
      );

      return {
        groupId: item,
        data: filteredData,
        title: filteredData?.[0]?.group_name,
        groupOrder: filteredData?.[0]?.group_order,
      };
    });

    const filteredGroups = groupedFilters?.filter(
      item => item?.data?.length > 0,
    );

    filteredGroups?.sort((a, b) => a?.groupOrder - b?.groupOrder);

    const otherData = ALL_MARKETS_ARRAY?.filter(item => !item?.group_id);

    return [
      // {
      //   groupId: 0,
      //   icon: 'star',
      //   title: 'fav',
      //   data: ALL_MARKETS_ARRAY?.filter(item =>
      //     favouriteMarkets?.includes?.(item?.market_type),
      //   ),
      // },
      {
        groupId: 1,
        data: ALL_MARKETS_ARRAY,
        title: 'All',
      },
      ...(groupedFilters?.length > 1 ? filteredGroups : []),
      ...(otherData?.length > 0 && groupedFilters?.length > 1
        ? [
            {
              groupId: null,
              title: 'other',
              data: ALL_MARKETS_ARRAY?.filter(item => !item?.group_id),
            },
          ]
        : []),
    ];
  }, [ALL_MARKETS_ARRAY, GROUP_IDS_ARRAY, favouriteMarkets]);

  const DROPDOWN_DATA = useMemo(
    () =>
      GROUP_DATA?.map(({groupId, title}) => ({
        value: groupId,
        name: `${title}`,
      })),
    [GROUP_DATA],
  );

  const ACTIVE_DATA = useMemo(
    () => GROUP_DATA?.find(item => item?.groupId === activeGroupId),
    [GROUP_DATA, activeGroupId],
  );

  const marketsArray = useMemo(
    () => Object.values(ACTIVE_DATA?.data || {}),
    [ACTIVE_DATA?.data],
  );

  const ACTIVE_DATA_UNIQUE_MARKETS = useMemo(
    () => [...new Set(ACTIVE_DATA?.data?.map?.(item => item?.name))],
    [ACTIVE_DATA],
  );

  const SEARCHED_ACTIVE_DATA_UNIQUE_MARKETS = useMemo(
    () =>
      !!searchValue
        ? ACTIVE_DATA_UNIQUE_MARKETS?.filter(item =>
            item?.toLowerCase()?.includes(searchValue?.toLowerCase()),
          )
        : ACTIVE_DATA_UNIQUE_MARKETS,
    [ACTIVE_DATA_UNIQUE_MARKETS, searchValue],
  );

  const LIST_DATA = useMemo(
    () =>
      SEARCHED_ACTIVE_DATA_UNIQUE_MARKETS?.map?.(item => {
        const filteredArray = ACTIVE_DATA?.data?.filter?.(
          filteredItem => filteredItem?.name === item,
        );
        return {
          data: filteredArray,
          key:
            filteredArray?.[0]?.name +
            filteredArray?.[0]?.id +
            filteredArray?.[0]?.type,
          name: filteredArray?.[0]?.name,
          cashout: !!filteredArray?.[0]?.cashout,
          market_type: filteredArray?.[0]?.market_type,
        };
      }),
    [ACTIVE_DATA, SEARCHED_ACTIVE_DATA_UNIQUE_MARKETS],
  );

  const sortedGameInfo = useMemo(() => {
    const team1Stats = stats?.find?.(item => item?.EntityId === game?.team1_id);
    const team2Stats = stats?.find?.(item => item?.EntityId === game?.team2_id);
    return [
      ...(team1Stats?.EntityId ? [team1Stats] : []),
      ...(team1Stats?.EntityId ? [team2Stats] : []),
    ];
  }, [game, stats]);

  const onMessageCb = useCallback(
    event => {
      const eventData = JSON.parse(event?.data);

      if (eventData?.rid === GET_SPECIFIC_GAME) {
        if (Object.values(eventData?.data?.data?.sport || {})?.length === 0) {
          setGetParam({});
        } else {
          setSubId(eventData?.data?.subid);
          setData(eventData?.data?.data);
        }
        setIsLoading(false);
      } else if (eventData?.data?.[subId]) {
        const changedGame =
          eventData?.data?.[subId]?.sport?.[sport?.id]?.region?.[region?.id]
            ?.competition?.[competition?.id]?.game?.[game?.id];

        const newData = {...data};

        if (
          changedGame === null ||
          changedGame?.info?.current_game_state === 'finished'
        ) {
          if (activeSportCount > 0) {
            getParam.delete('sportGameId');
            setGetParam(getParam);
          } else {
            navigate(`/sports`);
          }
          return;
        } else if (typeof changedGame?.info !== 'undefined') {
          newData.sport[sport?.id].region[region?.id].competition[
            competition?.id
          ].game[game?.id].info = {
            ...data.sport[sport?.id].region[region?.id].competition[
              competition?.id
            ].game[game?.id].info,
            ...changedGame?.info,
          };
        }

        if (typeof changedGame?.stats !== 'undefined') {
          if (typeof changedGame.stats !== 'undefined') {
            newData.sport[sport?.id].region[region?.id].competition[
              competition?.id
            ].game[game?.id].stats.passes = {
              ...data.sport[sport?.id].region[region?.id].competition[
                competition?.id
              ].game[game?.id].stats.passes,
              ...changedGame.stats.passes,
            };
          }

          if (
            typeof changedGame.stats[
              `score_${game?.info?.current_game_state}`
            ] !== 'undefined'
          ) {
            newData.sport[sport?.id].region[region?.id].competition[
              competition?.id
            ].game[game?.id].stats[`score_${game?.info?.current_game_state}`] =
              {
                ...data.sport[sport?.id].region[region?.id].competition[
                  competition?.id
                ].game[game?.id].stats[
                  `score_${game?.info?.current_game_state}`
                ],
                ...changedGame.stats[`score_${game?.info?.current_game_state}`],
              };
          }
        }

        if (changedGame?.is_blocked) {
          setIsBlocked(!!changedGame?.is_blocked);
        }

        if (changedGame?.market) {
          // HANDLE MARKET CHANGES
          const prevMarkets = {...game?.market};

          for (const market in changedGame?.market) {
            if (changedGame?.market?.[market] === null) {
              delete prevMarkets[market];
            } else if (
              typeof prevMarkets?.[market] === 'undefined' ||
              Object.keys(prevMarkets?.[market])?.length === 0
            ) {
              // HANDLE MARKET ADDING CASE
              prevMarkets[market] = changedGame?.market?.[market];
            } else if (prevMarkets?.[market]) {
              // HANDLE MARKET PRICE CHANGING
              const changedEventObject = changedGame?.market?.[market]?.event;

              const prevEvents = {...prevMarkets[market].event};

              for (const changedEvent in changedEventObject) {
                if (changedEventObject[changedEvent] === null) {
                  // HANDLE EVENT DELETING CASE
                  delete prevEvents[changedEvent];
                } else if (
                  typeof prevEvents[changedEvent] === 'undefined' ||
                  Object.keys(prevEvents[changedEvent])?.length === 0
                ) {
                  // HANDLE EVENT ADDING CASE
                  prevEvents[changedEvent] =
                    changedGame?.market?.[market].event[changedEvent];
                } else if (prevEvents?.[changedEvent]) {
                  // HANDLE EVENT PRICE CHANGING
                  prevEvents[changedEvent] = {
                    ...(prevEvents?.[changedEvent] || {}),
                    ...(changedEventObject?.[changedEvent] || {}),
                    lastPrice: (prevEvents?.[changedEvent] || {})?.price,
                  };
                }
              }
              prevMarkets[market].event = prevEvents;
            }
          }

          newData.sport[sport?.id].region[region?.id].competition[
            competition?.id
          ].game[game?.id].market = prevMarkets;
        }

        setData(newData);
      }
    },
    [
      data,
      subId,
      game?.id,
      getParam,
      navigate,
      sport?.id,
      region?.id,
      setGetParam,
      game?.market,
      competition?.id,
      activeSportCount,
      game?.info?.current_game_state,
    ],
  );

  useEffect(() => {
    setIsBlocked(game?.is_blocked);
    setTextInfo(game?.text_info);
  }, [game?.is_blocked, game?.text_info, game?.info?.current_game_time]);

  useEffect(() => {
    setActiveGroupId(1);

    if (!!sportGameIdParam) {
      setIsLoading(true);
      getSpecificGame({gameId: +sportGameIdParam});
    }

    return () => {
      unSubscribeLiveEvents({subId: subId});
    };
  }, [sportGameIdParam]);

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  useEffect(() => {
    setIsStatsLoading(true);
    const storageFavouriteMarkets = getFavouriteMarketsFromStorage();
    setFavouriteMarkets(storageFavouriteMarkets);
    fetch(
      `https://krosstats.betcoapps.com/api/${language}/900/93f428d0-6591-48da-859d-b6c326db2448/Entity/GetGeneralStatsInfo?matchId=${sportGameIdParam}`,
    )
      .then(response => response.json())
      .then(statsData => setStats(statsData?.GeneralStatsInfoResult))
      .catch(err => console.log(err))
      .finally(() => setIsStatsLoading(false));
  }, []);

  useEffect(() => {
    document
      .querySelector('.mobileHeaderContainerWrapper')
      ?.scrollIntoView(false);
  }, []);

  return (
    <div className="specificGameContainer">
      {isLoading ? (
        <SelectedGameSkeleton />
      ) : (
        <div
          className="gamesWrapper pa-sm rounded-borders"
          style={{
            border: width > BREAKPOINT_XS ? '1px solid var(--appYellow)' : '',
          }}>
          <div className="full-width items-center flex infoHeader">
            <div
              className="cursor-pointer rowCenter"
              onClick={() => {
                getParam.delete('sportGameId');
                getParam.delete('market');
                getParam.delete('marketFilter');
                setGetParam(getParam);
              }}>
              <SingleGameBack width={22} height={22} />
            </div>
            <img
              width={11}
              height={11}
              alt="sportIcon"
              className="sportIcon"
              src={SportIcons?.[game?.sport_alias]}
            />
            <span className="blueText bold-600 font-12">{sport?.name}</span>
            <div className="blueBackground line"></div>
            <img
              alt="flag"
              width={15}
              height={10}
              className="flag"
              src={Flags?.[region?.alias]}
            />
            <span className="blueText bold-600 ellipsis font-12 competitionName">
              {competition?.name}
            </span>
          </div>
          <div
            className={`${
              width < BREAKPOINT_XS ? 'mobile' : 'desktop'
            } blueBackground selectedGameHeaderWrapper scroll-auto-y scroll-2`}>
            <Header game={game} />
          </div>
          {/*<div>*/}
          {/*  <div className="rowCenter justify-between gap-5 statsWrapper">*/}
          {/*    {SINGLE_GAME_STATICS_FILTERS?.map(item => (*/}
          {/*      <div*/}
          {/*        key={item?.icon}*/}
          {/*        className={`${*/}
          {/*          selectedStatsFilter === item?.value*/}
          {/*            ? 'yellowBackground'*/}
          {/*            : 'whiteBackground'*/}
          {/*        } rowCenter cursor-pointer statsItem`}*/}
          {/*        onClick={() => setSelectedStatsFilter(item?.value)}>*/}
          {/*        <img*/}
          {/*          src={require(*/}
          {/*            `../../../Assets/Icons/SingleGameStatics/${item?.icon}.svg`,*/}
          {/*          )}*/}
          {/*        />*/}
          {/*      </div>*/}
          {/*    ))}*/}
          {/*  </div>*/}
          {/*</div>*/}
          {
            selectedStatsFilter === TRANSACTION_FILTER && !!game?.is_live ? (
              <div className="transactionContainer flex justify-center">
                <LiveTransaction />
              </div>
            ) : null
            // <div
            //   className="rowCenter"
            //   style={{
            //     height: 225,
            //     width: '100%',
            //     backgroundColor: 'white',
            //   }}>
            //   <span>WILL BE NEXT FILTERS</span>
            // </div>
          }
          <div className="flex rowCenter gap-10">
            <AppSelect
              type={1}
              className="select"
              value={activeGroupId}
              options={DROPDOWN_DATA}
              setValue={setActiveGroupId}
              titleClassName="selectedTitle"
            />
            <AppInput
              search
              type={4}
              height={35}
              inputValue={searchValue}
              onChange={setSearchValue}
              className="pa-sm mobileSize"
              placeholder={t('searchMarketUpper')}
            />
          </div>
          <div style={{marginBottom: 6, marginTop: 6}}>
            <FiltersList
              GROUP_DATA={GROUP_DATA}
              activeGroupId={activeGroupId}
              setActiveGroupId={setActiveGroupId}
            />
          </div>
          {/*{!!stats?.length &&*/}
          {/*  (isStatsLoading ? (*/}
          {/*    <div className="mx-md">*/}
          {/*      <GameStatsSkeleton />*/}
          {/*    </div>*/}
          {/*  ) : (*/}
          {/*    <MobileGameStats*/}
          {/*      team1Name={game?.team1_name}*/}
          {/*      team2Name={game?.team2_name}*/}
          {/*      sortedGameInfo={sortedGameInfo}*/}
          {/*      shirt1Color={game?.info?.shirt1_color}*/}
          {/*      shirt2Color={game?.info?.shirt2_color}*/}
          {/*    />*/}
          {/*  ))}*/}
          <MarketsList
            isBlocked={isBlocked}
            LIST_DATA={LIST_DATA}
            team1Name={game?.team1_name}
            team2Name={game?.team2_name}
            favouriteMarkets={favouriteMarkets}
            setFavouriteMarkets={setFavouriteMarkets}
          />
        </div>
      )}
    </div>
  );
};

export default memo(SelectedGame);
