import {
  SOCCER,
  TENNIS,
  CRICKET,
  VOLLEYBALL,
  BASKETBALL,
  TABLE_TENNIS,
  CYBER_FOOTBALL,
} from '../../Constants/SportsAlias';

import TennisSetsScore from './Tennis/TennisSetsScore';
import SoccerSetsScore from './Soccer/SoccerSetsScore';
import CricketSetsScore from './Cricket/CricketSetsScore';
import TennisGlobalScore from './Tennis/TennisGlobalScore';
import SoccerGlobalScore from './Soccer/SoccerGlobalScore';
import CricketGlobalScore from './Cricket/CricketGlobalScore';
import VolleyballSetsScore from './Volleyball/VolleyballSetsScore';
import BasketballSetsScore from './Basketball/BasketballSetsScore';
import TableTennisSetsScore from './TableTennis/TableTennisSetsScore';
import BasketballGlobalScore from './Basketball/BasketballGlobalScore';
import VolleyballGlobalScore from './Volleyball/VolleyballGlobalScore';
import TableTennisGlobalScore from './TableTennis/TableTennisGlobalScore';
import CyberFootballSetsScore from './CyberFootball/CyberFootballSetsScore';
import CyberFootballGlobalScore from './CyberFootball/CyberFootballGlobalScore';
import SoccerTitleScore from './Soccer/SoccerTitleScore';
import VolleyballTitleScore from './Volleyball/VolleyballTitleScore';
import BasketballTitleScore from './Basketball/BasketballTitleScore';
import CricketTitleScore from './Cricket/CricketTitleScore';
import TennisTitleScore from './Tennis/TennisTitleScore';
import TableTennisTitleScore from './TableTennis/TableTennisTitleScore';
import CyberFootballTitleScore from './CyberFootball/CyberFootballTitleScore';

const Scoring = ({game = {}, isGlobalScore = false, isTitleScore = false}) => {
  const renderScoringComponent = () => {
    switch (game?.sport_alias) {
      case SOCCER:
        return isGlobalScore ? (
          <SoccerGlobalScore game={game} showLiveInfo />
        ) : isTitleScore ? (
          <SoccerTitleScore game={game} />
        ) : (
          <SoccerSetsScore game={game} />
        );
      case VOLLEYBALL:
        return isGlobalScore ? (
          <VolleyballGlobalScore game={game} />
        ) : isTitleScore ? (
          <VolleyballTitleScore game={game} />
        ) : (
          <VolleyballSetsScore game={game} />
        );
      case BASKETBALL:
        return isGlobalScore ? (
          <BasketballGlobalScore game={game} />
        ) : isTitleScore ? (
          <BasketballTitleScore game={game} />
        ) : (
          <BasketballSetsScore game={game} />
        );
      case CRICKET:
        return isGlobalScore ? (
          <CricketGlobalScore game={game} />
        ) : isTitleScore ? (
          <CricketTitleScore game={game} />
        ) : (
          <CricketSetsScore game={game} />
        );
      case TENNIS:
        return isGlobalScore ? (
          <TennisGlobalScore game={game} />
        ) : isTitleScore ? (
          <TennisTitleScore game={game} />
        ) : (
          <TennisSetsScore game={game} />
        );
      case TABLE_TENNIS:
        return isGlobalScore ? (
          <TableTennisGlobalScore game={game} />
        ) : isTitleScore ? (
          <TableTennisTitleScore game={game} />
        ) : (
          <TableTennisSetsScore game={game} />
        );
      case CYBER_FOOTBALL:
        return isGlobalScore ? (
          <CyberFootballGlobalScore game={game} />
        ) : isTitleScore ? (
          <CyberFootballTitleScore game={game} />
        ) : (
          <CyberFootballSetsScore game={game} />
        );
      default:
        return isGlobalScore ? (
          <SoccerGlobalScore game={game} />
        ) : (
          <SoccerTitleScore game={game} />
        );
    }
  };

  return <>{renderScoringComponent()}</>;
};

export default Scoring;
