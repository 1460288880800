import React from 'react';
import {useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Countdown, {zeroPad} from 'react-countdown';

import Scoring from '../../Scoring';

import {getDateColor} from '../../../Utils/DateFormatter';

import {AppBlinkingLiveIcon, AppLiveIndicator} from '../../UI';

import {ReactComponent as TimerIcon} from '../../../Assets/Icons/Globals/timer.svg';

import {FILTERS_LIVE_CALENDAR} from '../../../Constants/MatchFilters';
const ScoreInfo = ({
  isToday,
  gameItem,
  startTime,
  isGameLive,
  minutesBeforeGame,
}) => {
  const {t} = useTranslation();
  const location = useLocation();

  return location?.pathname?.includes(`/${FILTERS_LIVE_CALENDAR}`) ? (
    <div className="flex items-center px-sm">
      <TimerIcon fill="var(--gray)" width={15} height={15} />
      <span className="text-caption-small ml-xs">
        {startTime?.split?.('-')?.[1]}
      </span>
    </div>
  ) : isToday &&
    !gameItem?.is_live &&
    !!minutesBeforeGame &&
    +minutesBeforeGame < 3600 ? (
    <div className="flex items-center gap-5 blueText px-sm">
      <TimerIcon fill="var(--black)" width={10} height={10} />
      <span className="text-caption-small">
        {/*<span>{t('startingIn')}</span>*/}
        <Countdown
          key={minutesBeforeGame}
          date={Date.now() + +minutesBeforeGame * 1000}
          renderer={({hours, minutes, seconds, completed}) => (
            <span className="blackText bold-700 font-10">
              {zeroPad(minutes)}{' '}
            </span>
          )}
        />
        <span className="blackText bold-700 font-10">{t('min')}</span>
        {/*<span style={getDateColor(startTime)} className="bold-700">*/}
        {/*  {startTime?.split('-')?.[1]}*/}
        {/*</span>*/}
      </span>
    </div>
  ) : isGameLive ? (
    <div className="row items-center font-13 gap-8 px-sm">
      <AppBlinkingLiveIcon />
      <AppLiveIndicator type={2} />
      <Scoring game={gameItem} isTitleScore />
      {/*{gameItem?.info?.set_count !== 1 &&*/}
      {/*  !currentGameTime &&*/}
      {/*  typeof currentGameLastSet?.team1_value !== 'undefined' && (*/}
      {/*    <span className="orangeText bold-600">*/}
      {/*      {currentGameLastSet?.team1_value}-*/}
      {/*      {currentGameLastSet?.team2_value}*/}
      {/*    </span>*/}
      {/*  )}*/}
      {/*{currentGameTime && currentGameTime !== 'undefined' && (*/}
      {/*  <span className="blueText bold-600">*/}
      {/*    {currentGameTime?.trim?.()}`*/}
      {/*  </span>*/}
      {/*)}*/}
      {/*{currentGameState && typeof currentGameState !== 'undefined' && (*/}
      {/*  <span className="bold-700 orangeText">*/}
      {/*    {t(*/}
      {/*      formatGameStates({*/}
      {/*        gameState: currentGameState,*/}
      {/*        sportAlias: gameItem?.sport_alias,*/}
      {/*      }).split(' ')?.[0],*/}
      {/*    )}{' '}*/}
      {/*    <span className="blueText bold-400">*/}
      {/*      {t(*/}
      {/*        formatGameStates({*/}
      {/*          gameState: currentGameState,*/}
      {/*          sportAlias: gameItem?.sport_alias,*/}
      {/*        }).split(' ')?.[1],*/}
      {/*      )}*/}
      {/*    </span>*/}
      {/*  </span>*/}
      {/*)}*/}
    </div>
  ) : (
    <span className="font-11 bold-700 px-sm" style={getDateColor(startTime)}>
      {startTime}
    </span>
  );
};

export default ScoreInfo;
